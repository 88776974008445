import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Nouislider from "@x1mrdonut1x/nouislider-react";
import 'react-toastify/dist/ReactToastify.css'
import "nouislider/distribute/nouislider.css"
import classNames from 'classnames'
import { toast } from 'react-toastify'
import Switch from './Switch.js'
import services from '../services'
import { APIURL } from './../../constants'
import {
  formatCM,
  formatHeightMetric,
  formatMM,
  formatWeightMetric,
  formatWeight,
  formatHeight } from './widgets/formats'

const MAX_MIN_RANGE = 188
const WHEN_TO_SCALE_UP_WIDTH = 178


const bmi_ideal = {
  58: 138,
  59: 141,
  60: 144,
  61: 147,
  62: 150,
  63: 153,
  64: 156,
  65: 159,
  66: 162,
  67: 165,
  68: 168,
  69: 171,
  70: 174,
  71: 177,
  72: 180,
  73: 183,
  74: 186,
  75: 189,
  76: 192,
}

toast.configure()

class SkiSizeWidget extends Component {

  constructor (props) {
    super(props)
    const td = this.props.topic_data
    this.state = {
      height: this.checkIfValue(td, 'height') ? td[5].input_values['height'] : 0,
      startHeight: this.checkIfValue(td, 'height') ? parseInt(td[5].input_values['height'][0]) : 67,
      weight: this.checkIfValue(td, 'weight') ? td[5].input_values['weight'] : 0,
      startWeight: this.checkIfValue(td, 'weight') ? parseInt(td[5].input_values['weight'][0]) : 180,
      metricWeight: this.checkIfValue(td, 'metricHeight') ? parseInt(td[5].input_values['metricWeight'][0]) : null,
      metricHeight: this.checkIfValue(td, 'metricHeight') ? parseInt(td[5].input_values['metricHeight'][0]) : null,
      start_mWeight: this.checkIfValue(td, 'metricHeight') ? parseInt(td[5].input_values['metricWeight'][0]) : null,
      start_mHeight: this.checkIfValue(td, 'metricHeight') ? parseInt(td[5].input_values['metricHeight'][0]) : null,
      show_sliders: td && td[5].input_values && td[5].input_values['weight'] ? true : false,
      min: 0,
      max: 0,
      widthMin: 0,
      widthMax: 0,
      start: [],
      widthStart: [],
      lowerSize: 0,
      upperSize: 0,
      lowerWidth: 0,
      upperWidth: 0,
      ref: null,
      widthRef: null,
      rendering: true,
      show_metric: td && td[5].input_values && td[5].input_values['show_metric'] ? true : false,
      user_info_save: false
    }
  }

  checkIfValue (td, key) {
    return (td && td[5].input_values && td[5].input_values[key] && td[5].input_values[key][0])
  }

  componentDidMount = () => {
    const td = this.props.topic_data
    if (this.props.logged_in) {
      services.get_v2(APIURL.USER_PROFILE, {params: {}}).then((response) =>{
        let user_info = response
        let show_metric = user_info.use_metric
        if (td && td[5].input_values && td[5].input_values['show_metric'] === false) {
          show_metric = false
        } else if (td && td[5].input_values && td[5].input_values['show_metric'] === true) {
          show_metric = true
        }
        let calculate_needed = false
        let user_info_save = true
        if (user_info.height && user_info.weight) {
          calculate_needed = true
          user_info_save = false
        }
        this.setState({
          user_profile: true,
          startHeight: this.checkIfValue(td, 'height')         || user_info.height        || 67,
          start_mHeight: this.checkIfValue(td, 'metricHeight') || user_info.metric_height || 171,
          startWeight: this.checkIfValue(td, 'weight')         || user_info.weight        || 180,
          start_mWeight: this.checkIfValue(td, 'metricWeight') || user_info.metric_weight || 80,
          show_metric: show_metric,
          calculate_needed: calculate_needed,
          user_info_save: user_info_save
        })
      })
    } else {
      if (typeof window !== "undefined") {
        let user_info = JSON.parse(window.localStorage.getItem('calculator_values'))
        if (user_info) {
          let show_metric = user_info['show_metric']
          if (td && td[5].input_values && td[5].input_values['show_metric'] === false) {
            show_metric = false
          } else if (td && td[5].input_values && td[5].input_values['show_metric'] === true) {
            show_metric = true
          }
          let calculate_needed = false
          if (user_info.height && user_info.weight) {
            calculate_needed = true
          }
          this.setState({
            user_profile: true,
            startHeight: this.checkIfValue(td, 'height')         || user_info.height        || 67,
            start_mHeight: this.checkIfValue(td, 'metricHeight') || user_info.metricHeight || 171,
            startWeight: this.checkIfValue(td, 'weight')         || user_info.weight        || 180,
            start_mWeight: this.checkIfValue(td, 'metricWeight') || user_info.metricWeight || 80,
            show_metric: show_metric,
            calculate_needed: calculate_needed,
          })
        }
      }
    }
  }

  componentDidUpdate = () => {
    if (this.state.ref && this.state.widthRef && this.state.calculate_needed) {
      let user_info = JSON.parse(window.localStorage.getItem('calculator_values'))
      this.calculateSizeRange(this.state.startHeight, this.state.startWeight)
    }
  }

  createRange = (min_size, max_size, min_width, max_width) => {
    // console.log('createRange')
    const {lowerSize, upperSize, lowerWidth, upperWidth} = this.state
    var sizeList = [];
    var widthList = [];
    if (min_size && max_size) {
      for (let i = min_size; i <= max_size; i++) {
          sizeList.push(String(parseInt(i)));
      }
    } else if (lowerSize && upperSize) {
      for (let i = lowerSize; i <= upperSize; i++) {
          sizeList.push(String(parseInt(i)));
      }
    }
    if (min_width && max_width) {
      for (let i = min_width; i <= max_width; i++) {
          widthList.push(String(parseInt(i)));
      }
    } else if (lowerWidth && upperWidth) {
      for (let i = lowerWidth; i <= upperWidth; i++) {
          widthList.push(String(parseInt(i)));
      }
    }
    if (this.state.show_sliders) {
      this.props.setWidgetValues(sizeList, widthList, {
        height: this.state.height,
        weight: this.state.weight,
        show_metric: this.state.show_metric,
        metricWeight: this.state.metricWeight,
        metricHeight: this.state.metricHeight
      }, false, this.state.user_info_save)
    }
  }

  checkSizes1 = (value) => {
    // console.log('checkSizes1')
    let size_range = document.getElementById('size_range')
    if (size_range) {
      const {max} = this.state
      let tooltips = size_range.getElementsByClassName('noUi-tooltip')
      let left_tooltip = tooltips[0]
      let right_tooltip = tooltips[1]
      if(value[1] - value[0] <= 8) {
        let connect = document.getElementsByClassName('noUi-connects')
        let offset_left = Math.round(connect[0].getBoundingClientRect().width) / 40 / 2 * -(value[1] - value[0]) + "px"
        if (Math.round(connect[0].getBoundingClientRect().width) / 2 < 30 && parseInt(value[1]) === max && value[0] !== value[1]) {
          offset_left = "-30px"
        }
        left_tooltip.classList.add('display-none')
        right_tooltip.classList.add('large')
        let calc_string = "calc(50% + " + offset_left+")"
        right_tooltip.style.setProperty('left', calc_string)
        if (value[0] === value[1]) {
          right_tooltip.innerHTML = (Math.floor(value[0]) + "cm" + (parseInt(value[1]) === max ? "+" : ""))
        } else {
          right_tooltip.innerHTML = (Math.floor(value[0]) + "cm - " + Math.floor(value[1])+ "cm" + (parseInt(value[1]) === max ? "+" : ""))
        }
      } else {
        left_tooltip.classList.remove('display-none')
        right_tooltip.classList.remove('large')
        right_tooltip.style.setProperty('left', '50%')
        if (parseInt(value[1]) === max) {
          right_tooltip.innerHTML = (Math.floor(value[1])+ "cm+")
        } else {
          right_tooltip.innerHTML = (Math.floor(value[1])+ "cm")
        }
      }

      this.setState({lowerSize: parseInt(value[0]), upperSize: parseInt(value[1])})
      this.createRange(parseInt(value[0]), parseInt(value[1]), null, null)
    }
  }

  checkSizes2 = (value) => {
    // console.log('checkSizes2')
    let width_range = document.getElementById('width_range')
    if (width_range) {
      const {widthMax} = this.state
      let tooltips = document.getElementById('width_range').getElementsByClassName('noUi-tooltip')
      let left_tooltip = tooltips[0]
      let right_tooltip = tooltips[1]
      if(value[1] - value[0] <= 10) {
        let connect = document.querySelectorAll(".noUi-connects")
        let offset_left = Math.round(connect[0].getBoundingClientRect().width) / 50 / 2 * -(value[1] - value[0]) + "px"
        if (Math.round(connect[0].getBoundingClientRect().width) / 2 < 35 && parseInt(value[1]) === widthMax && value[0] !== value[1]) {
          offset_left = "-38px"
        }
        left_tooltip.classList.add('display-none')
        right_tooltip.classList.add('large')
        let calc_string = "calc(50% + " + offset_left+")"
        right_tooltip.style.setProperty('left', calc_string)
        if (value[0] === value[1]) {
          right_tooltip.innerHTML = (Math.floor(value[1])+ "mm" + (parseInt(value[1]) === widthMax ? "+" : ""))
        } else {
          right_tooltip.innerHTML = (Math.floor(value[0]) + "mm - " + Math.floor(value[1])+ "mm" + (parseInt(value[1]) === widthMax ? "+" : ""))
        }
      } else {
        left_tooltip.classList.remove('display-none')
        right_tooltip.classList.remove('large')
        right_tooltip.style.setProperty('left', '50%')
        if (parseInt(value[1]) === widthMax) {
          right_tooltip.innerHTML = (Math.floor(value[1])+ "mm+")
        } else {
          right_tooltip.innerHTML = (Math.floor(value[1])+ "mm")
        }
      }

      this.setState({lowerWidth: parseInt(value[0]), upperWidth: parseInt(value[1])})
      this.createRange(null, null, parseInt(value[0]), parseInt(value[1]))
    }
  }

  getStartingRange = (topic_data) => {
    // console.log('getStartingRange')
    let min = 0
    let max = 0
    let start = []
    let lower_size = 0
    let upper_size = 0
    let lower_width = 0
    let upper_width = 0
    let width_min = 60
    let width_max = 120
    let width_start = []
    if (topic_data && topic_data[1].value === 'mens') {
      min = 140
      max = 194
      if (topic_data && topic_data[5].value) {
        lower_size = topic_data[5].value[0]
        upper_size = topic_data[5].value[topic_data[5].value.length - 1]
        start = [lower_size, upper_size]
      } else {
        lower_size = 140
        upper_size = 170
      }
      start = [lower_size, upper_size]
      width_min = 60
      width_max = 120
    } else {
      min = 140
      max = 194
      if (topic_data && topic_data[5].value) {
        lower_size = topic_data[5].value[0]
        upper_size = topic_data[5].value[topic_data[5].value.length - 1]
        start = [lower_size, upper_size]
      } else {
        lower_size = 140
        upper_size = 194
        start = [lower_size, upper_size]
      }
    }
    if (topic_data && topic_data[5].secondary_values) {
      lower_width = topic_data[5].secondary_values[0]
      upper_width = topic_data[5].secondary_values[topic_data[5].secondary_values.length - 1]
      width_start = [lower_width, upper_width]
    } else {
      upper_width = 60
      lower_width = 120
      width_start = [60, 120]
    }

    this.setState({
      min: min,
      max: max,
      start: start,
      widthStart: width_start,
      lowerSize: lower_size,
      upperSize: upper_size,
      lowerWidth: lower_width,
      upperWidth: upper_width,
      widthMin: width_min,
      widthMax: width_max,
      rendering: false
    })
    this.createRange(lower_size, upper_size, 244, 251)
  }

  calculateSizeRange = (height, weight) => {
    // console.log('calculateSizeRange')

    if (this.state.calculate_needed) {
      this.setState({calculate_needed: false})
    }
    const { topic_data } = this.props
    let min_range = 0
    let max_range = 0
    let min_width = 0
    let max_width = 0
    let ideal_min = bmi_ideal[parseInt(height)]
    // weight_num = weight / 10

    height /= 39.3700787
    weight /= 2.20462
    let bmi = weight / Math.pow(height, 2);
    let bmi_diff = 0
    if (bmi > 28) {
      bmi_diff = Math.ceil(Math.ceil((bmi - 30))/2)
      bmi = 28
    }
    if (bmi < 21) {
      bmi_diff = -1 * Math.ceil(Math.ceil(21 - bmi))
      bmi = 21
    }
    let abs = Math.abs(24 - bmi)
    let abs_ratio = Math.pow(abs/24, 2)
    let calc_mid = ideal_min * (1 + abs_ratio)
    if (bmi < 24) {
      calc_mid = ideal_min * (1 - abs_ratio)
    }
    max_range = Math.round(calc_mid) + 4 + bmi_diff
    min_range = Math.round(calc_mid) - 4 + bmi_diff

    if (topic_data[2].value === 'beginner'){
      min_range = min_range - 6
      max_range = max_range - 4
    } else if (topic_data[2].value === 'advanced'){
      min_range = min_range + 3
      max_range = max_range + 3
    } else if (topic_data[2].value === 'expert'){
      min_range = min_range + 5
      max_range = max_range + 5
    }
    if (topic_data[3].value === 'powder'){
      min_range = min_range + 3
      max_range = max_range + 3
      min_width = 100
      max_width = 120
    } else if (topic_data[3].value === 'park_&_pipe'){
      min_range = min_range - 6
      max_range = max_range - 4
      min_width = 80
      max_width = 100

      if (topic_data[2].value === 'advanced'){
        min_range = min_range - 3
        max_range = max_range - 3
      } else if (topic_data[2].value === 'expert'){
        min_range = min_range - 5
        max_range = max_range - 5
      }

    } else if (topic_data[3].value === 'big_mountain'){
      min_range = min_range + 2
      max_range = max_range + 2
      min_width = 90
      max_width = 110
    } else if (topic_data[3].value === 'all_mountain'){
      min_width = 80
      max_width = 100
    } else if (topic_data[3].value === 'alpine_touring'){
      min_range = min_range + 1
      max_range = max_range + 1
      min_width = 90
      max_width = 110
    } else if (topic_data[3].value === 'carving') {
      min_range = min_range - 3
      max_range = max_range - 3
      min_width = 70
      max_width = 95
    }
    if (topic_data[2].value === 'beginner') {
      min_width = 60
      max_width = 90
    }
    if (topic_data[1].value === 'womens'){
      min_range = min_range - 1
      max_range = max_range - 1
    }

    if (topic_data[4].value && topic_data[4].value.indexOf('charging') > -1){
      // min_range = min_range - 2
      max_range = max_range + 2
      // min_width = min_width + 5
      if (max_width < 120) {
        max_width = max_width + 5
      }
    }
    if (min_range > MAX_MIN_RANGE) {
      min_range = MAX_MIN_RANGE
    }
    if (min_range > WHEN_TO_SCALE_UP_WIDTH) {
      let scale_up = min_range - WHEN_TO_SCALE_UP_WIDTH
      if (topic_data[3].value === 'all_mountain') {
        min_width = min_width + scale_up
        max_width = max_width + scale_up
      } else if (topic_data[3].value === 'park_&_pipe') {
        max_width = max_width + scale_up
      } else if (topic_data[3].value === 'alpine_touring') {
        max_width = max_width + scale_up
      } else if (topic_data[2].value === 'beginner') {
        max_width = max_width + scale_up
      }
    }

    if(topic_data[1].value === 'womens' && max_range >= 186) {
      toast.dismiss()
      toast.info(
        <div>
          <div className={'toast-text'}>
            There may be few or no women&#39;s skis at this size. You may want to consider switching to men&#39;s skis!
          </div>
          <div className={'click-here'} onClick={() => {this.props.goBackToQuestionX(1)}}>
            Click Here to Switch
          </div>
        </div>,
        {
          position: "bottom-left",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      })
    }

    if (this.state.ref && this.state.ref.noUiSlider) {
      this.state.ref.noUiSlider.set([min_range, max_range]);
    }

    //UNCOMMENT
    if (this.state.widthRef && this.state.widthRef.noUiSlider) {
      this.state.widthRef.noUiSlider.set([min_width, max_width]);
    }

    this.setState({
      lowerSize: min_range,
      upperSize: max_range,
      lowerWidth: min_width,
      upperWidth: max_width,
      show_sliders: true,
      calculate_needed: false,
    }, () => {
      this.createRange(min_range, max_range, min_width, max_width)
    })
  }

  toggleSwitch = () => {
    let {metricHeight, metricWeight, startHeight, startWeight} = this.state
    if (!this.state.show_metric) {
      let start_mWeight = this.state.metricWeight ? this.state.metricWeight : Math.round(this.state.startWeight / 2.2)
      let start_mHeight = this.state.metricHeight ? this.state.metricHeight : Math.round(this.state.startHeight * 2.54)
      this.setState({
        show_metric: !this.state.show_metric,
        start_mWeight: start_mWeight,
        start_mHeight: start_mHeight,
      })
    } else {
      this.setState({show_metric: !this.state.show_metric})
    }
  }

  render () {
    const {
      height,
      weight,
      min,
      max,
      widthMin,
      widthMax,
      widthStart,
      start,
      rendering,
      startWeight,
      startHeight,
      metricWeight,
      metricHeight,
      start_mHeight,
      start_mWeight
    } = this.state
    if (min === 0) {
      this.getStartingRange(this.props.topic_data)
    }

    return (<div>
      {rendering && (
        <div className="spinner-container">
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      )}
      {!rendering && (
        <div className="snowboard-widget">
          <div className="calc-sliders">
            <h1 id="size-calc">Size Calculator</h1>
            <Switch containerClasses={"switch-container mt-sm"} label={'Use Metric Units'} checked={this.state.show_metric} switchChange={this.toggleSwitch}/>
            <div>
              {!this.state.show_metric && (
                <div>
                  <div className="slider-container">
                    <Nouislider
                      onUpdate={(value) => {this.setState({height: value, metricHeight: Math.round((value * 2.54))})}}
                      step={1}
                      range={{ min: 58, max: 76 }}
                      start={[startHeight]}
                      tooltips={formatHeight(76)}
                      connect
                    />
                    <div className="slider-label">Height</div>
                  </div>
                  <div className="slider-container">
                    <Nouislider
                      onUpdate={(value) => {
                        // this.setState({metricWeight: value, weight: [5 * Math.round((value * 2.2) / 5)]})}}
                        this.setState({weight: value, metricWeight: Math.round((value / 2.2))})
                      }}
                      step={5}
                      range={{ min: 90, max: 270 }}
                      start={[startWeight]}
                      tooltips={formatWeight(270)}
                    />
                    <div className="slider-label">Weight</div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {this.state.show_metric && (
                <div>
                  <div className="slider-container">
                    <Nouislider
                      onUpdate={(value) => {
                        let h = [Math.round(value / 2.54)]
                        this.setState({metricHeight: value, height: h})
                      }}
                      step={1}
                      range={{ min: 148, max: 194 }}
                      start={[start_mHeight]}
                      tooltips={formatHeightMetric(194)}
                      connect
                    />
                    <div className="slider-label">Height</div>
                  </div>
                  <div className="slider-container">
                    <Nouislider
                      onUpdate={(value) => {
                        this.setState({metricWeight: value, weight: [5 * Math.round((value * 2.2) / 5)]})}}
                      step={2}
                      range={{ min: 40, max: 120 }}
                      start={[start_mWeight]}
                      tooltips={formatWeightMetric(120)}
                    />
                    <div className="slider-label">Weight</div>
                  </div>
                </div>
              )}
            </div>
            <div className="btn-center-container">
            <button id="get-my-sizes" className={"action-btn-gld continue-btn btn-m10 small"} onClick={
              () => {
                this.calculateSizeRange(height, weight)
                let element = document.getElementById('bottom-div')
                if (element.getBoundingClientRect().bottom + 50 >= (window.innerHeight || document.documentElement.clientHeight)) {
                  let element1 = document.getElementById('size-calc')
                  element1.scrollIntoView({'behavior': 'smooth'})
                }
              }}>Get My Sizes
            </button>
            </div>
          </div>
          {true && (
            <div className={'slider-fade ' + classNames({'display-none': !this.state.show_sliders})}>
              <div className="size-adjust-text ta-center">Sizes can be adjusted to your liking</div>
              <div className="slider-container bottom-sliders">
                <Nouislider
                  id='size_range'
                  animate= {true}
                  onUpdate={(e) => {this.checkSizes1(e)}}
                  step={1}
                  range={{ min: min, max: max }}
                  start={start}
                  tooltips={[formatCM(max), formatCM(max)]}
                  connect={true}
                  instanceRef={(instance) => {
                    if (instance && !this.state.ref) {
                      this.setState({ref: instance})
                    }
                  }}
                />
                <div className="slider-label">Ski Size</div>
                </div>
                <div className="slider-container bottom-sliders">
                  <Nouislider
                    id='width_range'
                    animate= {false}
                    onUpdate={(e) => {this.checkSizes2(e)}}
                    step={1}
                    range={{ min: widthMin, max: widthMax }}
                    start={widthStart}
                    tooltips={[formatMM(widthMax), formatMM(widthMax)]}
                    connect={true}
                    instanceRef={(instance) => {
                      if (instance && !this.state.widthRef) {
                        this.setState({widthRef: instance})
                      }
                    }}
                  />
                <div className="slider-label">Ski Waist Width</div>
              </div>
            </div>
          )}
          <div id="bottom-div"></div>
        </div>
      )}
      </div>
    )
  }
}

SkiSizeWidget.propTypes = {
  topic_data: PropTypes.object,
  goBackToQuestionX: PropTypes.func,
  logged_in: PropTypes.bool

}

export default SkiSizeWidget
