// const baseUrl = process.env.NEXT_PUBLIC_API_URL
// const siteUrl = process.env.NEXT_PUBLIC_SITE_URL
// const baseUrl_v2 = process.env.NEXT_PUBLIC_API_V2_URL

export default {
  // BASE_URL: baseUrl,
  // BASE_URL_V2: baseUrl_v2,
  // SITE_URL: siteUrl,
  // SITE_PAGE_URL: siteUrl + 'pages/',
  GET_SAVED_RESULTS: 'api/user/question-data/',
  QUESTION_DATA: 'api/question-data/',
  PRODUCTS: 'api/get/products/',
  PRODUCT_COUNT: 'api/count/products/',
  TOPIC_QUESTIONS: 'api/product/questions/',
  SAVE_RESULTS: 'api/save-results/',
  MAKE_COPY: 'api/make-copy/',
  ADD_LINK_CLICK: 'api/add-link-click/',
  ADD_PRODUCT_VIEW: 'api/add-product-view/',
  UNDO_DELETE_SAVED_RESULT: 'api/saved_result/undo/',
  DELETE_SAVED_RESULT: 'api/saved_result/delete/',
  CONTACT_US: 'api/contact-us/',
  REPORT_BUG: 'api/report-bug/',
  ADD_EXCLUDED_PRODUCT: 'api/exclude-product/',
  PRICE_DROP_ALERTS: 'api/price-drop-alerts/',
  GET_BLOG_POSTS: 'api/blog-posts/',
  CHANGE_LOCKED_VALUE: 'api/change-locked-value/',
  UNSUBSCRIBE: 'api/unsubscribe/',
  PRICE_DROP_ALERTS_BY_USER: 'api/get-price-alerts-user/',
  USER_PROFILE: 'api/users/',
  UPDATE_USER_INFO: 'api/update-user-info/',
  CHANGE_PASSWORD: 'change-password/',
  GET_COMMISSION: 'api/get-commission/',
  GET_COMMISSION_ADMIN: 'api/get-commission-admin/',
  GOOGLE_SIGN_IN: 'api/google-sign-in/',
  RECOMMENDATION_ENGINE: 'api/engine/',
  ENGINE_PRODUCTS: 'api/engine/products/',
  ENGINE_VOTE: 'api/engine/vote/',
  GET_ENGINES: 'api/get-engines/',
  GET_TOPICS: 'api/get-topics/',
  GET_BLOG_POST_TITLES: 'api/get-blog-post-titles/'
}
