import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { APIURL } from './../../constants'
import services from '../services'
import classNames from 'classnames'
import SelectField from './SelectField.js'
import { GrClose } from '@react-icons/all-files/gr/GrClose'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logError } from '../Logger'
import Switch from './Switch.js'

toast.configure()
// import SelectField from '../../../../components/forms/SelectField'

const slug_options = [
  {value: '', label: 'All'},
  {value: 'barbell', label: 'Barbells'},
  {value: 'mountain-bike', label: 'Mountain Bikes'},
  {value: 'ski', label: 'Skis'},
  {value: 'snowboard', label: 'Snowboards'}
]

class UserPriceAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_alerts: null,
      selections: null,
      show_preview: null,
      preview_array: [],
      mounting: true,
      slug_filter: '',
      slug_options: [],
      initial_load_complete: false
    }
  }

  componentDidMount = async () => {
    if (this.props.logged_in) {
      const response = await services.get_v2(APIURL.PRICE_DROP_ALERTS_BY_USER, {params: {slug_filter: this.state.slug_filter}})
      this.setState({user_alerts: response, mounting: false})
    }
  }

  componentDidUpdate = async () => {
    if (this.props.logged_in && !this.state.initial_load_complete) {
      const response = await services.get_v2(APIURL.PRICE_DROP_ALERTS_BY_USER, {params: {slug_filter: this.state.slug_filter}})
      this.setState({user_alerts: response, mounting: false, initial_load_complete: true})
    }
  }

  handleSelectFilterChange = async (e, filter_id) => {
    try {
      let value = ''
      if (e) {
        value = e.value
      }
      this.setState({mounting: true, slug_filter: e})
      const response = await services.get_v2(APIURL.PRICE_DROP_ALERTS_BY_USER, {params: {slug_filter: value}})
      this.setState({user_alerts: response, mounting: false})
    } catch (e) {
      logError(e, "handleSelectFilterChange")
    }
  }

  updateAlert = async (mute, user_alert_id) => {
    let slug_filter = this.state.slug_filter ? this.state.slug_filter.value : ''
    const response = await services.post_v2(APIURL.PRICE_DROP_ALERTS_BY_USER, {
      user_alert_id: user_alert_id,
      mute: mute,
      slug_filter: this.state.slug_filter.value,
    })
    if (response.success) {
      this.setState({user_alerts: response.data})
    } else {
      toast.error('An error occured trying to update this price alert!')
    }
  }

  deletePriceAlert = async (user_alert_id) => {
    try {
      const response = await services.post_v2(APIURL.PRICE_DROP_ALERTS_BY_USER, {
        user_alert_id: user_alert_id,
        is_deleted: true,
        slug_filter: this.state.slug_filter,
      })
      if (response.success) {
        toast(
          <div>
            <div>Price Alert Deleted!</div>
            <div className="pt-md">
              <a className="like-link" onClick={() => { this.undoDelete(user_alert_id) }}>Undo</a>
            </div>
          </div>
        )
        this.setState({user_alerts: response.data})
      } else {
        toast.error('An error occured trying to uda this price alert')
      }
    } catch (e) {
      toast.error('An error occured trying to delete this price alert')
      logError(e, "Error Deleting Price Alert")
    }
  }

  undoDelete = async (user_alert_id) => {
    try {
      const response = await services.post_v2(APIURL.PRICE_DROP_ALERTS_BY_USER, {
        user_alert_id: user_alert_id,
        is_deleted: false,
        slug_filter: this.state.slug_filter,
      })
      if (response.success) {
        toast(<div>Deletion  Undone!</div>)
        this.setState({user_alerts: response.data})
      } else {
        toast.error('An error occured trying to undo')
      }
    } catch (e) {
      toast.error('An error occured trying to undo')
      logError(e, "Error Deleting Price Alert")
    }
  }

  handleChange(e, i) {
    const { user_alerts } = this.state
    user_alerts[i].price_to_beat_formatted = event.target.value
    this.setState({user_alerts: user_alerts});
    services.put_v2(APIURL.PRICE_DROP_ALERTS_BY_USER, {
      user_alert_id: user_alerts[i].id,
      price_to_beat: event.target.value
    })
  }

  render () {
    const alerts = []
    const { user_alerts } = this.state
    for (const i in user_alerts) {
      if (user_alerts[i].id) {
        let alternate = ""
        if (i % 2 === 0) {
          alternate = 'alternate-color'
        }
        // const preview_text = this.state.preview_array.includes(i) : Hide Preview ? Preview
        alerts.push(
          <tr key={'tr-'+i} className={alternate}>
            <td data-label="Product">{user_alerts[i].product_slug}</td>
            <td data-label="Brand">{user_alerts[i].product_brand}</td>
            <td data-label="Name">{user_alerts[i].product_name}</td>
            <td data-label="Price To Beat">
              <div className="price-to-beat-data">
                <div className="prefix">$</div>
                <input
                  className='price-to-beat'
                  type="number"
                  value={parseFloat(user_alerts[i].price_to_beat_formatted)}
                  onChange={(e) => {this.handleChange(e, i)}}
                />
                </div>
            </td>
            <td data-label="Mute" className="switch">
              <Switch
                label={''}
                checked={user_alerts[i].mute}
                switchChange={() => {
                    this.updateAlert(!user_alerts[i].mute, user_alerts[i].id)
              }}/>
            </td>
            <td className="white" data-label="Delete">
              <GrClose className="white" onClick={() => {
                this.deletePriceAlert(user_alerts[i].id)
              }}/>
            </td>
          </tr>
        )
      }
    }
    return (
      <div className='home'>
        <div className="home-container">
          <div className="manage-price-alerts">
            <h1 className={""}>Manage Price Alert</h1>
            <div className={'filter-label'}>Product Filter</div>
            <SelectField
              id={'slug-filter'}
              defaultValue={slug_options[0]}
              className='filterSelect mb-xl'
              isClearable={true}
              onChange={(e) => { this.handleSelectFilterChange(e, 'slug-filter') } }
              options={slug_options}
              placeholder={'All'}
              value={this.state.slug_filter}
              label={'Product Filter'}>
            </SelectField>
            {alerts.length > 0 && (
              <div>
                {this.state.mounting && (
                  <div className="spinner-container not-full">
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </div>
                )}
                {!this.state.mounting && (
                <div className="alerts-flex">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Brand</th>
                        <th scope="col" width="25%">Name</th>
                        <th scope="col" className="price-to-beat-col">Price To Beat</th>
                        <th scope="col">Mute</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alerts}
                    </tbody>
                  </table>
                </div>
                )}
              </div>
            )}
            {alerts.length === 0 && (
              <div>
              {this.state.mounting && (
                <div className="spinner-container not-full">
                  <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
              )}
              {!this.state.mounting && (
              <div className="no-alerts">
                You have no price alerts yet!
              </div>
              )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

UserPriceAlerts.propTypes = {
  logged_in: PropTypes.bool
}

// export default SavedResult
export default UserPriceAlerts
