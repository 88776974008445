import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { FormikField } from '../'
import { withRouter } from 'react-router-dom'
import Modal from 'react-overlays/lib/Modal'
import services from '../services'
// import { APIURL } from './../../constants'
import classNames from 'classnames'
// import SelectField from '../../../../components/forms/SelectField'
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectField from './SelectField.js'

toast.configure()

const bug_options = [
  {value: 'results', label: 'Results'},
  {value: 'questions', label: 'Questions'},
  {value: 'saved-results', label: 'Saved Results'},
  {value: 'login-register', label: 'Login/Register'},
  {value: 'other', label: 'Other'}
]

const backdropStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#000',
  opacity: 0.5
}

class ReportBug extends React.Component {
  state = {
    show: this.props.show,
    register: false,
    bug_select: ''
  }

  renderBackdrop (props) {
    return <div {...props} style={backdropStyle} onClick={this.props.hide} />
  }

  hide = (e) => {
    this.setState({ show: false })
  }

  routePage (page, event) {
    this.props.store.routePage(page)
    event.preventDefault()
  }

  handleSelectFilterChange = async (e) => {
      this.setState({bug_select: e})
  }

  render () {
    let disable_submit = false
    if (this.state.bug_select === '') {

    }

    return (
      <div>
        {this.props.show && (
          this.renderBackdrop()
        )}
        <Modal
          onHide={ this.props.hide }
          className={ 'member-modal login'}
          aria-labelledby="modal-label"
          show={ this.props.show }
          >
            <div className="modal-content">
              <div className={'w-100'}>
                <h3 className="mt-none">Report Bug</h3>
                <Formik
                  initialValues = {{
                    category: '',
                    text: '',
                  }}
                  validationSchema={Yup.object({
                    category: Yup.string(),
                    text: Yup.string().required('Required')
                  })}
                  onSubmit={ async (values, { setSubmitting, setErrors }) => {
                    const response = await services.post_v2("api/report-bug/", values)
                    if (response.statusCode===200) {
                      this.props.hide()
                      toast('Thank you for reporting a bug. We will take a look and try to get it fixed ASAP.')
                    } else {
                      toast('There was an error sending your message. We have been notified and will fix it ASAP.')
                    }
                    setSubmitting(false)
                  }}
                >
                  {props => (
                    <Form>
                      <div className="">
                        <FormikField name="subject" label="Category" required={true} >
                          <Field as="select" name="category" id="category" className="form-field mt-none">
                            <option value="" label="select a category...">
                            </option>
                            {bug_options.map(item =>
                             <option key={'item-'+item} value={item.value} label={item.label}>{item.value}</option>
                            )}
                          </Field>
                        </FormikField>
                        <FormikField name="text" label="Confirm Password" required={true} >
                          <Field as="textarea" className="form-field contact-textarea" name="text" placeholder="Describe bug here..." type="text" ></Field>
                        </FormikField>
                        <div className="btn-center-container">
                          <button disabled={disable_submit} type="submit" className="action-btn-gld">Submit</button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
          </div>
        </Modal>
      </div>
    )
  }
}

ReportBug.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  handlelogin: PropTypes.func,
  // results_url: PropTypes.func
}

// export default SaveResultsModal
export default ReportBug
