import React from 'react';

function Circles () {

  return (
    <div>
      <div class="wrap">
        <div class="cube">
          <div id="circle1" class="circle circle1"></div>
          <div id="circle2" class="circle circle2"></div>
          <div id="circle3" class="circle circle3"></div>
          <div id="circle4" class="circle circle4"></div>
          <div id="circle5" class="circle circle5"></div>
          <div id="circle6" class="circle circle6"></div>
          <div id="circle7" class="circle circle7"></div>
          <div id="circle8" class="circle circle8"></div>
          <div id="circle9" class="circle circle9"></div>
          <div id="circle10" class="circle circle10"></div>
          <div id="circle11" class="circle circle11"></div>
          <div id="circle12" class="circle circle12"></div>
          <div id="circle13" class="circle circle13"></div>
          <div id="circle14" class="circle circle14"></div>
          <div id="circle15" class="circle circle15"></div>
          <div id="circle16" class="circle circle16"></div>
          <div id="circle17" class="circle circle17"></div>
          <div id="circle18" class="circle circle18"></div>
          <div id="circle19" class="circle circle19"></div>
        </div>
      </div>
      <div id="circles-tagline" class="circles-tagline">Simplify Your Search For The Perfect Product</div>
    </div>
  )
}

export default Circles
