import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { FormikField } from '../'
import { withRouter } from 'react-router-dom'
import Modal from 'react-overlays/lib/Modal'
import services from '../services'
// import { APIURL } from './../../constants'
import classNames from 'classnames'
// import SelectField from '../../../../components/forms/SelectField'
import * as Yup from 'yup'
import { APIURL } from './../../constants'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import CustomGoogleButton from './CustomGoogleButton.js'
import {IoIosCloseCircleOutline} from '@react-icons/all-files/io/IoIosCloseCircleOutline';

const backdropStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#000',
  opacity: 0.5
}

class LoginModal extends React.Component {
  state = {
    show: this.props.show,
    register: false
  }

  renderBackdrop (props) {
    return <div {...props} style={backdropStyle} onClick={this.props.hide} />
  }

  hide = (e) => {
    this.setState({ show: false })
  }

  routePage (page, event) {
    this.props.store.routePage(page)
    event.preventDefault()
  }

  toggleMenu() {
    this.setState({register: !this.state.register})
  }

  render () {
    return (
      <div>
        {this.props.show && (
          this.renderBackdrop()
        )}
        <Modal
          onHide={ this.props.hide }
          className={ 'member-modal login'}
          aria-labelledby="modal-label"
          show={ this.props.show }
          >
            <div className="modal-content">
              <IoIosCloseCircleOutline className={'modal-close clickable'} onClick={()=>{this.props.hide()}}/>

              <div className={classNames({'display-none': !this.state.register})}>
                <h3 className="mt-none">Register</h3>
                <div className="register-text">
                  Create your account. It&#39;s free and all your SimplyBuy results will be accessible in one place!
                </div>
                <CustomGoogleButton
                  handlelogin={this.props.handlelogin}
                  buttonText={'Sign In With Google'}
                  separatorText={'Or Register'}
                  callBack={() => {this.props.hide()}}
                  />
                <Formik
                  initialValues = {{
                    username: '',
                    password: '',
                    confirm_password: '',
                  }}
                  validationSchema={Yup.object({
                    username: Yup.string().email().required('Required'),
                    password: Yup.string().required('Required')
                      .required('Required'),
                    confirm_password: Yup.string().oneOf([Yup.ref('password'), null],'Passwords do not match.').required('Required').typeError('Passwords do not match.')
                  })}
                  onSubmit={ async (values, { setSubmitting, setErrors }) => {
                    delete values['confirm_password']
                    const response = await services.post_v2("api/users/", values)
                    if (response.token) {
                      localStorage.setItem('token', response.token);
                      this.props.handlelogin(null, response.token)
                      if (localStorage.getItem('calculator_values')) {
                        let response = services.post_v2(APIURL.UPDATE_USER_INFO, {
                          calculator_values: JSON.parse(localStorage.getItem('calculator_values'))
                        })
                        localStorage.removeItem('calculator_values')
                      }
                      this.props.hide()
                    } else if (response.username) {
                      setErrors({username:"Email already taken"})
                    }
                    setSubmitting(false)
                  }}
                >
                  {props => (
                    <Form>
                      <div className="">
                        <FormikField name="username" label="Email" required={true} >
                          <Field className="form-field" name="username" placeholder="Email" type="text" />
                        </FormikField>
                        <FormikField name="password" label="Password" required={true} >
                          <Field className="form-field" name="password" placeholder="Password" type="password" />
                        </FormikField>
                        <FormikField name="confirm_password" label="Confirm Password" required={true} >
                          <Field className="form-field" name="confirm_password" placeholder="Confirm Password" type="password" />
                        </FormikField>
                        <div className="btn-center-container">
                          <button type="submit" className="action-btn-gld">Register</button>
                        </div>
                        <div className="ta-center pt-sm pb-sm">
                          Already a member? <span href="/#" className="pointer register-link" onClick={()=>{this.toggleMenu()}}>Login</span>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className={"w-100 " + classNames({'display-none': this.state.register})}>
                <h3 className="mt-none">Login</h3>
                <CustomGoogleButton
                  handlelogin={this.props.handlelogin}
                  buttonText={'Sign In With Google'}
                  separatorText={'Or Login'}
                  callBack={() => {this.props.hide()}}
                  />
                <Formik
                  initialValues = {{
                    username: '',
                    password: '',
                  }}
                  validationSchema={Yup.object({
                    username: Yup.string().email().required('Required'),
                    password: Yup.string().required('Required')
                      .required('Required'),
                  })}
                  onSubmit={ async (values, { setSubmitting, setErrors }) => {
                    const x = await this.props.handlelogin(values)
                    if (x === false) {
                      setSubmitting(false)
                      setErrors({username:"This email and password combination do not match any of our accounts."})
                    } else {
                      setSubmitting(false)
                      this.props.hide()
                      // if (typeof window !== "undefined") {
                      //   window.location.reload(false);
                      // }
                    }
                    // this.props.hide()
                    // window.location.reload(false);
                  }}
                >
                {props => (
                  <Form>
                    <div className="">
                      <FormikField name="username" label="Email" required={true} >
                        <Field className="form-field" name="username" placeholder="Email" type="text" />
                      </FormikField>
                      <FormikField name="password" label="Password" required={true} >
                        <Field className="form-field" name="password" placeholder="Password" type="password" />
                      </FormikField>
                      <div className="btn-center-container">
                        <button type="submit" className="action-btn-gld">Login</button>
                      </div>
                      <div className="ta-center pt-sm pb-sm">
                        Not a member yet? <span className="pointer register-link" onClick={()=>{this.toggleMenu()}}>Register now</span>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

LoginModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  handlelogin: PropTypes.func,
  // results_url: PropTypes.func
}

// export default SaveResultsModal
export default LoginModal
