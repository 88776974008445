import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { FormikField } from '../'
import { withRouter } from 'react-router-dom'
import Modal from 'react-overlays/lib/Modal'
import services from '../services'
import { APIURL } from './../../constants'
// import SelectField from '../../../../components/forms/SelectField'
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import CustomGoogleButton from './CustomGoogleButton.js'
import { GoogleOAuthProvider } from '@react-oauth/google';
import {IoIosCloseCircleOutline} from '@react-icons/all-files/io/IoIosCloseCircleOutline';

const backdropStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#000',
  opacity: 0.5
}

toast.configure()

class SaveResultsModal extends React.Component {
  state = {
    show: this.props.show
  }

  renderBackdrop (props) {
    return <div {...props} style={backdropStyle} onClick={this.props.hide} />
  }

  hide = (e) => {
    this.setState({ show: false })
  }

  copyCodeToClipboard = () => {
    const el = this.urlToCopy
    navigator.clipboard.writeText(el.value)
    // el.select()
    // document.execCommand("copy")
    toast('URL copied!')
  }


  routePage (page, event) {
    this.props.store.routePage(page)
    event.preventDefault()
  }

  render () {
    return (
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH}>
      <div>
        {this.props.show && (
          this.renderBackdrop()
        )}
        <Modal
        onHide={ this.props.hide }
        className={ 'member-modal'}
        aria-labelledby="modal-label"
        show={ this.props.show }
        >
          <div className="modal-content save-results-modal">

            <div className="register-form">
              <IoIosCloseCircleOutline className={'modal-close clickable'} onClick={()=>{this.props.hide()}}/>
              <h3 className="mt-none">{this.props.title_text}</h3>
              <div className="register-text">
                {this.props.description_text}
              </div>
              <CustomGoogleButton
                handlelogin={this.props.handlelogin}
                buttonText={'Sign In With Google'}
                separatorText={'Or Register'}
                callBack={() => {this.props.hide()}}
                />
              <Formik
                initialValues = {{
                  username: '',
                  password: '',
                  confirm_password: '',
                }}
                validationSchema={Yup.object({
                  username: Yup.string().email().required('Required'),
                  password: Yup.string().required('Required')
                    .required('Required'),
                  confirm_password: Yup.string().oneOf([Yup.ref('password'), null],'Passwords do not match.').required('Required').typeError('Passwords do not match.')
                })}
                onSubmit={ async (values, { setSubmitting, setErrors }) => {
                  delete values['confirm_password']
                  const response = await services.post_v2("api/users/", values)
                  if (response.token) {
                    this.props.handlelogin(null, response.token)
                    // localStorage.setItem('token', response.token);
                    this.props.hide()
                    if (localStorage.getItem('calculator_values')) {
                      let response = services.post_v2(APIURL.UPDATE_USER_INFO, {
                        calculator_values: JSON.parse(localStorage.getItem('calculator_values'))
                      })
                      localStorage.removeItem('calculator_values')
                    }
                    const saved_results = await services.post_v2(APIURL.SAVE_RESULTS, {data_uuid: this.props.data_uuid})
                    if (saved_results.response.data_uuid !== this.props.data_uuid) {
                      this.props.router.push('/product-picker/'+this.props.product_slug+'/' + this.props.current_question + '/?results=' + saved_results.response.data_uuid)
                    }
                    toast(
                      'Results Saved To Your Account!'
                    )
                  } else if (response.username) {
                      setErrors({username:"Email already taken"})
                  } else {
                    setSubmitting(false)
                  }
                  // localStorage.setItem('token', response.token);
                  // setSubmitting(false)
                  // this.props.hide()
                }}
              >
              {props => (
                <Form>
                  <div className="">
                    <FormikField name="username" label="Email" required={true} >
                      <Field className="form-field" name="username" placeholder="Email" type="text" />
                    </FormikField>
                    <FormikField name="password" label="Password" required={true} >
                      <Field className="form-field" name="password" placeholder="Password" type="password" />
                    </FormikField>
                    <FormikField name="confirm_password" label="Confirm Password" required={true} >
                      <Field className="form-field" name="confirm_password" placeholder="Confirm Password" type="password" />
                    </FormikField>
                    <div className="btn-center-container">
                      <button type="submit" className="action-btn-gld">Register</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/*<div className="quick-save">
            <h3 className="mt-none quick-link">or Quick Link</h3>
            <div className="register-text">
              Use the link below to share or revisit these results.
            </div>
            <div className="copy-container">
              <input disabled={true} className={'form-field'} ref={(urlToCopy) => this.urlToCopy = urlToCopy} value={this.props.results_url}></input>
              <div className="btn-center-container">
                <button type="" className="action-btn-gld" onClick={() => this.copyCodeToClipboard()}>
                  Copy
                </button>
              </div>
            </div>
          </div>*/}
        </div>
      </Modal>
      </div>
      </GoogleOAuthProvider>
    )
  }
}

SaveResultsModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  handleSubmit: PropTypes.func,
  results_url: PropTypes.string,
  data_uuid: PropTypes.string,
  current_question: PropTypes.number,
  product_slug: PropTypes.string,
  handlelogin: PropTypes.func,
  title_text: PropTypes.string,
  description_text: PropTypes.string,
}

// export default SaveResultsModal
export default SaveResultsModal
