import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

class SelectField extends Component {
    handleChange = value => {
      const { onChange, name } = this.props
      onChange(name, value)
    }

    render () {
      const {
        id,
        placeholder,
        options,
        value,
        isMulti,
        isClearable,
        backspaceRemovesValue,
        className,
        components,
        maxMenuHeight,
        defaultValue,
      } = this.props
      return (
        <Select
          id={id}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={this.props.onChange}
          onblur={this.props.onblur}
          isMulti={isMulti}
          isClearable={isClearable}
          backspaceRemovesValue={backspaceRemovesValue}
          components={components}
          className={className}
          maxMenuHeight={maxMenuHeight}
          defaultValue={defaultValue}
        />
      )
    }
}

SelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isClearable: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  components: PropTypes.object,
  maxMenuHeight: PropTypes.number,
}

export default SelectField
