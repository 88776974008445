import React from 'react';
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import services from '../services'
import PropTypes from 'prop-types'
import {CONSTANTS} from '../../constants'
// import { inject, observer } from 'mobx-react'
import { APIURL, PAGEURL } from './../../constants'
import { add_unit, getToastMessage, getCostSymbols } from '../utils'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick-theme.css"
import SaveResultsModal from './SaveResultsModal.js'
import BottomNav from './BottomNav'
import Switch from './Switch.js'
import LazyImg from './LazyImg.js'
import SnowboardSizeWidget from './SnowboardSizeWidget.js'
import ProductSlider from './ProductSlider.js'
import MountainBikeSizeWidget from './MountainBikeSizeWidget.js'
import SnowboardBindingSizeWidget from './SnowboardBindingSizeWidget.js'
import TvSliders from './TvSliders.js'
import SkiSizeWidget from './SkiSizeWidget.js'
import RadarComponent from './Radar.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsQuestionCircleFill } from "@react-icons/all-files/bs/BsQuestionCircleFill";
import { BsStopwatch } from "@react-icons/all-files/bs/BsStopwatch";
import { BsQuestionCircle } from "@react-icons/all-files/bs/BsQuestionCircle";
import { BsFillCaretRightFill } from "@react-icons/all-files/bs/BsFillCaretRightFill";
import { BsFillCaretLeftFill } from "@react-icons/all-files/bs/BsFillCaretLeftFill";
import { BsInfoSquare } from '@react-icons/all-files/bs/BsInfoSquare'
import { BsQuestionSquare } from '@react-icons/all-files/bs/BsQuestionSquare'
import { BsInfoCircleFill } from '@react-icons/all-files/bs/BsInfoCircleFill'
import { FiPlus } from '@react-icons/all-files/fi/FiPlus'
import { FiMinus } from '@react-icons/all-files/fi/FiMinus'
import { IoMdRemoveCircle } from '@react-icons/all-files/io/IoMdRemoveCircle'
import { FaSnowboarding } from '@react-icons/all-files/fa/FaSnowboarding'
import { FaStar } from '@react-icons/all-files/fa/FaStar'
import { GiCheckeredFlag } from "@react-icons/all-files/gi/GiCheckeredFlag"
import { GiCheckMark } from "@react-icons/all-files/gi/GiCheckMark"
import { IoMdOptions } from "@react-icons/all-files/io/IoMdOptions"
import { IoMdFunnel } from "@react-icons/all-files/io/IoMdFunnel"
import { BsFillGrid3X3GapFill } from '@react-icons/all-files/bs/BsFillGrid3X3GapFill'
import { IoMdThumbsDown } from '@react-icons/all-files/io/IoMdThumbsDown'
import { IoMdThumbsUp } from '@react-icons/all-files/io/IoMdThumbsUp'
import Image from 'next/image'
import Link from 'next/link'
// import FingerprintJS from '@fingerprintjs/fingerprintjs'
import ReactTooltip from "react-tooltip";
import { logError, logInfo } from '../Logger'
import { TypeAnimation } from 'react-type-animation';

// import { useRouter } from 'next/router'
// if (typeof window === 'undefined') {
//   import FingerprintJS from '@fingerprintjs/fingerprintjs'
// }
//
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
//
// Sentry.init({
//   dsn: "https://ffe23113959946faaa986b4ccd7b9b81@o1032396.ingest.sentry.io/5999351",
//   integrations: [new Integrations.BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.5,
// })

//const queryString = require('query-string')
toast.configure()

let fpPromise = null
if (typeof window !== "undefined") {
import('@fingerprintjs/fingerprintjs')
  .then((module) => {
    fpPromise = module.load()
    // Do something with the module.
  });
// const fpPromise = FingerprintJS.load()
}
const STATIC_PRODUCT_ROWS = 3

class Result extends React.Component {
  static propTypes = {
    results: PropTypes.array,
    resultType: PropTypes.string,
    prevValue: PropTypes.func,
    nextValue: PropTypes.func,
    position: PropTypes.number,
    views: PropTypes.array,
    pickbutton: PropTypes.string
  }

  componentDidMount() {
      const { results, resultType, views, position, pickbutton } = this.props
      let result = this.props.results[position]
      let product_id = result.props['data-product-id']
      let isHighend = true ? resultType === 'highend' : false
  }

  componentDidUpdate () {
    const { results, resultType, views, position, pickbutton } = this.props
    let result = this.props.results[position]
    let product_id = result.props['data-product-id']
    let isHighend = true ? resultType === 'highend' : false
  }

  render () {
    const { results, resultType, views } = this.props
    let wrapperClass = ""
    let containerClass = ""
    let pick_label = ""
    let isHighend = false
    if (resultType === 'not-top') {
      containerClass = 'result-container not-top'
    } else if (resultType === 'value') {
      pick_label = "Value Picks"
      wrapperClass = 'top-picks-wrapper'
      containerClass = 'result-container top-picks'
    } else if (resultType === 'highend') {
      pick_label = "High-end Picks"
      wrapperClass = 'top-picks-wrapper high-end'
      containerClass = 'result-container top-picks'
      isHighend = true
    }
    let count = (this.props.position + 1) + " / " + this.props.results.length
    let result = this.props.results[this.props.position]
    let product_id = result.props['data-product-id']

    return (
      <div>
        <div className={wrapperClass}>
          <div className={'pick-label'}>
            <div>
              {this.props.results.length > 1 && (
                <BsFillCaretLeftFill className={'clickable '} onClick={() => {this.props.prevValue(product_id)}}/>
              )}
            </div>
            <div>
              {pick_label}
            </div>
            <div>
              {count}
            </div>
            <div>
              {this.props.results.length > 1 && (
                <BsFillCaretRightFill className={'clickable '} onClick={() => {this.props.nextValue(product_id)}}/>
              )}
            </div>
          </div>
          <div className={containerClass}>
            {result}
          </div>
          <div className="bottom-flip-through">
            {this.props.results.length > 1 && (
              <BsFillCaretLeftFill onClick={() => {this.props.prevValue(product_id)}}/>
            )}
            <div>
            </div>
            <div>
              {count}
            </div>
            <div>
            </div>
            {this.props.results.length > 1 && (
              <BsFillCaretRightFill onClick={() => {this.props.nextValue(product_id)}}/>
            )}
          </div>
        </div>
      </div>
    )
  }
}


class BestResult extends React.Component {
  static propTypes = {
    best_result: PropTypes.object,
    high_end_result: PropTypes.object,
    toggleShowMoreResults: PropTypes.func,
    toggleShowMoreHighendResults: PropTypes.func,
    toggleShowMoreValueResults: PropTypes.func,
    display_value_products: PropTypes.array,
    display_highend_products: PropTypes.array,
    toggleEditFilters: PropTypes.func,
    data_uuid: PropTypes.string
  }

  constructor (props) {
    super(props)
    // this.toggleShowMoreResults = this.toggleShowMoreResults.bind(this)
    this.state = {
      index: 0,
      pickbutton: 'value',
      value_position: 0,
      highend_position: 0,
      views: [],
      switchClicked: false
    }
  }

  nextResult = () => {
    this.setState({index: this.state.index + 1})

  }

  previousResult = () => {
    this.setState({index: this.state.index - 1})
  }

  nextValue = (product_id) => {
    if (this.state.value_position + 1 === this.props.display_value_products.length) {
      this.setState({value_position: 0})
    } else {
      this.setState({value_position: this.state.value_position + 1})
    }
  }

  prevValue = (product_id) => {
    if (this.state.value_position - 1 === -1) {
      this.setState({value_position: this.props.display_value_products.length - 1})
    } else {
      this.setState({value_position: this.state.value_position - 1 })
    }
  }

  nextHighend= () => {
    if (this.state.highend_position + 1 === this.props.display_highend_products.length) {
      this.setState({highend_position: 0})
    } else {
      this.setState({highend_position: this.state.highend_position + 1})
    }
  }

  prevHighend= () => {
    if (this.state.highend_position - 1 === -1) {
      this.setState({highend_position: this.props.display_highend_products.length - 1})
    } else {
      this.setState({highend_position: this.state.highend_position - 1 })
    }
  }

  togglePick = () => {
    if (this.state.pickbutton === 'value') {
      this.setState({pickbutton: 'highend'})
    } else {
      this.setState({pickbutton: 'value'})
    }
    this.setState({switchClicked: true})
  }

  vote = async (vote, topic_slug, user_fingerprint) => {
    let response = await services.post_v2(APIURL.ENGINE_VOTE, {
      user_fingerprint: user_fingerprint,
      vote: vote,
      topic_slug: topic_slug
    })
    toast('Thank you for your feedback!')
  }

  render () {
    const {
      // toggleShowMoreValueResults,
      // toggleShowMoreHighendResults,
      display_value_products,
      display_highend_products,
      data_uuid,
      related_button_container,
      topic_slug,
      user_fingerprint
      // show_more_value,
      // show_more_highend
    } = this.props
    let best_results = display_value_products
    let high_end_results = display_highend_products
    // let queue = [
    //   {result_type: 'value', result: best_result},
    //   {result_type: 'highend', result: high_end_result}
    // ]

    let queue = {
      'value': {result_type: 'value', results: best_results, position: this.state.value_position, nextValue: this.nextValue, prevValue: this.prevValue},
      'highend': {result_type: 'highend', results: high_end_results, position: this.state.highend_position, nextValue: this.nextHighend, prevValue: this.prevHighend}
    }
    return (
      <div>
        {best_results.length > 0 && (
          <div>
            <div className="top-pick-text hidden-sm-down">Top Picks For You</div>
            <div></div>
            <div className="desktop-top-picks">
              <div className="more-results ">
                <div>
                  <Result
                    prevValue={queue['value']['prevValue']}
                    nextValue={queue['value']['nextValue']}
                    position={queue['value']['position']}
                    results={queue['value']['results']}
                    resultType={queue['value']['result_type']}
                    views={this.state.views}
                    pickbutton={null}
                  />
                </div>
                {high_end_results.length > 0 && (
                  <div>
                    <Result
                      prevValue={queue['highend']['prevValue']}
                      nextValue={queue['highend']['nextValue']}
                      position={queue['highend']['position']}
                      results={queue['highend']['results']}
                      resultType={queue['highend']['result_type']}
                      views={this.state.views}
                      pickbutton={null}
                    />
                  </div>
                )}
              </div>
              <div className="mt-lg mb-lg vote-container">
                <div>Was this Recommenation Engine helpful?</div>
                <div className="vote-buttons">
                  <button className="action-btn-gld blue continue-btn" onClick={() => this.vote('up', topic_slug, user_fingerprint)}><IoMdThumbsUp/></button>
                  <button className="action-btn-gld blue continue-btn" onClick={() => this.vote('down',topic_slug, user_fingerprint)}><IoMdThumbsDown/></button>
                </div>
              </div>
            </div>
            <div className="mobile-top-picks">
              {high_end_results.length > 0 && (
                <div className="switch-ctn-container">
                  <div className={"switch-label left " + classNames({'not-checked': this.state.pickbutton === 'highend'})}>Value</div>
                  <Switch
                    label={''}
                    checked={this.state.pickbutton === 'highend'}
                    offColor={"#06bef0"}
                    onColor={"#f08706"}
                    className={classNames({'pulse': this.state.switchClicked === false})}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    switchChange={() => {this.togglePick()}}
                  />
                  <div className={"switch-label right " + classNames({'not-checked': this.state.pickbutton === 'value'})}>High-end</div>
                </div>
              )}
              <div className="more-results ">
                <Result
                  prevValue={queue[this.state.pickbutton]['prevValue']}
                  nextValue={queue[this.state.pickbutton]['nextValue']}
                  position={queue[this.state.pickbutton]['position']}
                  results={queue[this.state.pickbutton]['results']}
                  resultType={queue[this.state.pickbutton]['result_type']}
                  views={this.state.views}
                  pickbutton={this.state.pickbutton}
                />
              </div>
              <div className="mt-lg vote-container">
                <div>Was this Recommenation Engine helpful?</div>
                <div className="vote-buttons">
                  <button className="action-btn-gld blue continue-btn" onClick={() => this.vote('up', topic_slug, user_fingerprint)}><IoMdThumbsUp/></button>
                  <button className="action-btn-gld blue continue-btn" onClick={() => this.vote('down',topic_slug, user_fingerprint)}><IoMdThumbsDown/></button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

class Results extends React.Component {
  static propTypes = {
    products: PropTypes.array,
    product_type: PropTypes.array
  }

  render () {
    const { products } = this.props
    const display = []
    for (const product in products) {
      display.push(<Result key={'result'+product} result={products[product]} resultType={this.props.product_type}/>)
    }
    return (
      <div className="more-results">
        {display}
      </div>
    )
  }
}

class Question extends React.Component {
  static propTypes = {
    logged_in: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      topic_data,
      current_question,
      show_more_options,
      product_slug,
      nextQuestion,
      makeSelection,
      makeSelectionMultiple,
      setWidgetValues,
      goBackToQuestionX,
      input_values,
      topic_status,
      related_button_container,
      topic_name,
      topic_name_plural,
      slider_data,
      goToSite,
      data_uuid
    } = this.props
    let num_questions = Object.keys(topic_data).length
    if (current_question !== 0 && !(current_question in topic_data)) {
      return null
    }
    return (
      <div>
        {current_question === 0 && (
          <div className="">
            <div className="ai-rec-engine top-title">
              <h1 className="">AI Recommendation Engines</h1>
              <div key="beta" className="beta-tag1"><span>Beta</span></div>
            </div>
            <h1 className="question-title">
              {topic_name}
            </h1>
            <ProductSlider
              slider_data={this.props.slider_data}
              goToSite={this.props.goToSite}
              data_uuid={this.props.data_uuid}/>
            <div className="pp-symbols">
              <div className="symbol">
                <div className="icon-wrapper">
                  <BsQuestionCircle/>
                </div>
                <div className="stat-block">
                  <div className="">{num_questions} Questions</div>
                </div>
              </div>
              <div className="symbol">
                <div className="icon-wrapper">
                  <BsStopwatch/>
                </div>
                <div className="stat-block">
                  <div className="">2 Minutes</div>
                </div>
              </div>
              <div className="symbol">
                <div className="icon-wrapper">
                  <GiCheckeredFlag/>
                </div>
                <div className="stat-block">
                  <div className="">2 value picks & 2 high-end picks</div>
                </div>
              </div>
            </div>
            <div className="btn-center-container">
              <button className="action-btn-gld continue-btn" onClick={() => nextQuestion()}>Start</button>
            </div>
            {related_button_container.length > 0  ? <div className="related-products-black starter mt-lg">{related_button_container}</div> : null}
          </div>
        )}
        {current_question > 0 && (
          <div className="w-100">
            <div className="question-title">{topic_data[current_question].question_title}</div>
            {topic_data[current_question].top_image && (
              <div className="lazy-wrapper">
                <LazyImg
                  src={`/images/products/${product_slug}/questions/${topic_data[current_question].top_image}`}
                  effect={'blur'}
                  width={topic_data[current_question].question_image_width ? topic_data[current_question].question_image_width : '100%'}
                  height={topic_data[current_question].question_image_height ? topic_data[current_question].question_image_height : 'auto'}
                  classNames='r-img'
                />
              </div>
            )}
            <div className="w-100" key={current_question}>
              <div className={"multiple-select " + classNames({'display-none': (topic_data[current_question].question_type !== 'multiple_select' && topic_data[current_question].question_type !== 'related_table_multiple' || topic_data[current_question].widget)})}>MULTIPLE SELECT</div>
              <div className={"multiple-select " + classNames({'display-none': topic_data[current_question].question_type !== 'or_greater'})}>SELECT MINIMUM</div>

              <div key={current_question} className={"calc-options-container " + classNames(
                  {
                    'mb-none': topic_data[current_question].options.length === 0,
                    'calc-options-1': topic_data[current_question].options.length === 1,
                    'calc-options-2': topic_data[current_question].options.length === 2,
                    'calc-options-3': topic_data[current_question].options.length === 3
                  }
                )
              }>
                {topic_data[current_question].options.map((option_data, index) => {
                  return (
                    <Option
                      key={index}
                      topic_data={topic_data}
                      question_data={topic_data[current_question]}
                      option_data={option_data}
                      product_slug={product_slug}
                      current_question={current_question}
                      question_type={topic_data[current_question].question_type}
                      makeSelection={makeSelection}
                      makeSelectionMultiple={makeSelectionMultiple}
                      show_more_options={show_more_options}
                      selected_options={topic_data[current_question].value}
                      option_image_height={topic_data[current_question].option_image_height}
                      option_image_width={topic_data[current_question].option_image_width}/>
                  )}
                )}
              </div>
              {topic_data[current_question].show_more && !show_more_options && (
                <div className="show-more pt-none" onClick={
                  () => { this.props.toggleShowMoreOptions() }}>
                    <FiPlus className="plus-minus"/>
                    Show More Options
                </div>
              )}
              {topic_data[current_question].show_more && show_more_options && (
                <div className="show-more pt-none" onClick={
                  () => { this.props.toggleShowMoreOptions() }}>
                    <FiMinus className="plus-minus"/>
                    Show Less Options
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

class Option extends React.Component {
  static propTypes = {
    // products: PropTypes.array
  }

  render() {
    const {
      option_data,
      topic_data,
      question_data,
      product_slug,
      current_question,
      question_type,
      makeSelection,
      makeSelectionMultiple,
      show_more_options,
      selected_options,
      option_image_width,
      option_image_height } = this.props

    const option_value = option_data.value
    const is_show_more_option = option_data.show_more
    let option_img = null
    let option_count_id = 'option-count-'+option_value
    if (option_value === 'True') {
      option_count_id = 'option-count-true'
    } else if (option_value === 'False') {
      option_count_id = 'option-count-false'
    }
    // let recommendations = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]
    // let show_tip = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]["show_tip"]
    // let alternate = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]["alternate"]

    // let is_recommended = false
    // if ((recommendations[user_type] &&
    //      recommendations[user_type][question_data.column] &&
    //      recommendations[user_type][question_data.column].indexOf(option_value) > -1)) {
    //     is_recommended = true
    //   }

    if (option_data.image) {
      const windowWidth = window.innerWidth;
      let option_image_width1 = option_image_width
      let option_image_height1 = option_image_height
      if (windowWidth < 814) {
        option_image_width1 = option_image_width1 * .75
        option_image_height1 = option_image_height1 * .75
      }
      option_img = (
        <LazyImg
          width={option_image_width1 + 'px'}
          height={option_image_height1 + 'px'}
          effect={"opacity"}
          src={`/images/products/${product_slug}/options/${option_data.image}`}
          classNames={'image-center'}/>
      )
    }

    return (
      <div
        key={option_data.value + '-option-container'}
        className={
          "m-xs " + classNames(
            {'display-none': is_show_more_option && !show_more_options && !(selected_options && selected_options.includes(option_data.value))}
          )
        }>
        {option_img}
        <div className={"option-container " + classNames({'recommended': option_data.popular})}>
        <div
          key={'question-'+current_question+option_value}
          id={option_data.label}
          className={"calc-options with-hover " + classNames({'any-option': option_value === '0', 'small-font': option_data.small_font})}
          onClick={(e) => {
            if (['multiple_select', 'related_table_multiple'].indexOf(question_type) > -1) {
              makeSelectionMultiple(e, option_data.label)
            } else {
              makeSelection(e, option_data.label)
            }
          }}>
          <div className="option-text">{option_data.label}</div>
          <div className="option-count">
            <div className="cost-symbols">{getCostSymbols(option_data.cost)}</div>
            <div id={option_count_id}>{}</div>
          </div>
        </div>
        {option_data.popular && (
          <div className="tool-tip-container recommended">
            Popular Pick
          </div>
        )}
        </div>
      </div>
  )}
}


class RecommendationEngine extends React.Component {
  static propTypes = {
    router: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.toggleShowMoreResults = this.toggleShowMoreResults.bind(this)
    this.toggleShowMoreHighendResults = this.toggleShowMoreHighendResults.bind(this)
    this.toggleShowMoreValueResults = this.toggleShowMoreValueResults.bind(this)
    this.toggleShowMoreOptions = this.toggleShowMoreOptions.bind(this)
    this.makeSelection = this.makeSelection.bind(this)
    this.makeSelectionMultiple = this.makeSelectionMultiple.bind(this)
    this.showSaveResultsModal = this.showSaveResultsModal.bind(this)
    this.getAlertsClick = this.getAlertsClick.bind(this)
    this.setState = this.setState.bind(this)
    this.state = {
      topic_data: {},
      questions: [],
      current_question: 0,
      current_selection: null,
      next_disabled: false,
      savings: null,
      tier_needed: 1,
      data_uuid: null,
      results_page: false,
      mounting: true,
      current_selections: null,
      is_advisor: true,
      old_savings: 0,
      product_slug: this.props.router.query.slug,
      matching_products: null,
      show_more: false,
      show_save_results_modal: false,
      results_url: null,
      close_sidebar: false,
      options_data: {},
      extras_added: false,
      show_constants: true,
      show_tips: false,
      show_more_options: false,
      show_more_highend: false,
      show_more_value: false,
      secondary_selections: null,
      input_values: null,
      referrer: '',
      source: '',
      campaign: '',
      topic_status: null,
      alert_ids: [],
      sidebar_toggle: false,
      description_toggle_bool: false,
      related_products_toggle_bool: false,
      edit_filters_toggle_bool: false,
      related_button_container: [],
      path: this.props.router.pathname.replace('[slug]/[question]', '')
    }
  }


  add_recommendations(current_question) {
    if (current_question !== 0) {
      try {
        const { topic_data } = this.state
        // let recommendations = CONSTANTS.RECOMMENDED_SELECTIONS[this.state.product_slug]
        // let show_tip = CONSTANTS.RECOMMENDED_SELECTIONS[this.state.product_slug]["show_tip"]

        let is_popular = false
        for (let option in topic_data[current_question].options) {
          if (topic_data[current_question].options[option].popular) {
            is_popular = true
          }
        }
        if (is_popular === true) {
          this.setState({next_disabled: false})
        }

        if ([topic_data[current_question].column]) {

        } else if (!topic_data[current_question].value) {
          this.setState({next_disabled: true})
        }
      } catch (err) {
        logError(err, "Try add_recommendations")
      }
    }
  }

  getFingerPrint = async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    const visitorId = result.visitorId
    return visitorId
  }

  toggleShowMoreResults () {
    this.setState({show_more: !this.state.show_more})
  }

  toggleShowMoreHighendResults () {
    this.setState({show_more_highend: !this.state.show_more_highend})
  }

  toggleShowMoreValueResults () {
    this.setState({show_more_value: !this.state.show_more_value})
  }

  toggleShowMoreOptions (show=false) {
    if (show) {
      this.setState({show_more_options: true})
    } else {
      this.setState({ show_more_options: !this.state.show_more_options })
    }
  }

  makeSelectionMultiple (e, value, widgetValues=null, secondary_values=null, input_values=null, select_buttons=null) {
    try{
      const {
        topic_data,
        current_question,
        product_slug,
        // current_selections
      } = this.state
      let recommendations = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]
      if (widgetValues) {
        this.setState({
          current_selections: widgetValues,
          secondary_selections: secondary_values,
          input_values: input_values,
          next_disabled: false,
        })
        if (select_buttons) {
          const current_selections = widgetValues
          topic_data[current_question].value = current_selections
          var selected_option = document.getElementsByClassName('selected')
          while(selected_option.length > 0){
            selected_option[0].classList.remove('selected');
            selected_option = document.getElementsByClassName('selected')
          }
          for (const index in widgetValues) {
            const option = document.getElementById(String(widgetValues[index]))
            option.classList.add('selected')
          }
          this.setState({ topic_data: topic_data, current_selections: current_selections })
        }
      } else if (!topic_data[current_question].widget || !widgetValues) {
        if (e) {
          if(!(e.target.hasAttribute('data-tip') || e.target.hasAttribute('fill-rule'))){
            // let current_selections = topic_data[current_question].value || []
            let current_selections = []
            if (topic_data[current_question].value) {
              current_selections = [...topic_data[current_question].value]
            }
            if (value === '0') {
              var selected_option = document.getElementsByClassName('selected')
              while(selected_option.length > 0){
                selected_option[0].classList.remove('selected');
                selected_option = document.getElementsByClassName('selected')
              }
              e.currentTarget.classList.add('selected')
              current_selections = [value]
              topic_data[current_question].value = current_selections

            } else if (e.currentTarget.classList.contains('selected')) {
              e.currentTarget.classList.remove('selected')
              const index = current_selections.indexOf(value)
              if (index > -1) {
                current_selections.splice(index, 1)
              }
              topic_data[current_question].value = current_selections
            } else {
              e.currentTarget.classList.add('selected')
              current_selections.push(value)
              topic_data[current_question].value = current_selections
              let selected_option = document.getElementsByClassName('any-option')
              if (selected_option.length > 0) {
                selected_option[0].classList.remove('selected');
              }
              const index = current_selections.indexOf('0')
              if (index > -1) {
                current_selections.splice(index, 1)
              }
              // while(selected_option.length > 0){
                // selected_option[0].classList.remove('selected');
                // selected_option = document.getElementsByClassName('any-option')
              // }
            }
            let next_disabled = topic_data[current_question].value && topic_data[current_question].value.length !== 0 ? false : true

            this.setState({
              current_selections: current_selections,
              topic_data: topic_data,
              next_disabled: next_disabled
            })
          }
        } else if (value || value === 0) {
          const current_selections = topic_data[current_question].value || []
          for (const index in value) {
            const option = document.getElementById(String(value[index]))
            if (option) {
              option.classList.add('selected')
            } else {
              logInfo("no options: " + value + " " + index)
            }
          }
          this.setState({ current_selections: current_selections })
        }
      }
    } catch (err) {
      logError(err, "makeSelectionMultiple")
    }

  }

  makeSelection (e, value) {
    if (e) {
      if(!(e.target.hasAttribute('data-tip') || e.target.hasAttribute('fill-rule'))){
        var selected_option = document.getElementsByClassName('selected')
        while(selected_option.length > 0){
          selected_option[0].classList.remove('selected');
          selected_option = document.getElementsByClassName('selected')
        }
        e.currentTarget.classList.add('selected')
        this.setState({ next_disabled: false, current_selection: value })
      }
    } else if (value || value === false) {
      const option = document.getElementById(String(value))
      option.classList.add('selected')
      this.setState({ next_disabled: false, current_selection: value })
    }
  }

  showSaveResultsModal (title_text, description_text) {
    this.setState({
      show_save_results_modal: true,
      title_text: title_text,
      description_text: description_text
    })
  }

  setWidgetValues = (size_list, width_list, input_values, select_buttons=null, user_info_save=null) => {
    // console.log('setWidgetValues')
    if (this.props.logged_in === 'true' && user_info_save) {

      try {
        if (window.localStorage.getItem('calculator_values')) {
          services.post_v2(APIURL.UPDATE_USER_INFO, {
            calculator_values: JSON.stringify(input_values)
          })
        }
      } catch (e) {
        logError(e, "window.localStorage.getItem")
      }
    }
    if (this.props.logged_in === 'false') {
      try {
        let calculator_values = window.localStorage.getItem('calculator_values')
        if (calculator_values) {
          calculator_values = JSON.parse(calculator_values)
          input_values = {...calculator_values, ...input_values}
        }
        window.localStorage.setItem('calculator_values', JSON.stringify(input_values))
      } catch (e) {
        logError(e, "window.localStorage.setItem")
      }
    }
    this.makeSelectionMultiple(null, null, size_list, width_list, input_values, select_buttons)
  }

  nextQuestion = async (skip_to_results=false) => {
    // console.log('nextQuestion')
    try {
      const {
        current_selection,
        current_selections,
        topic_data,
        current_question,
        secondary_selections,
        input_values,
        data_uuid,
        options_data,
        // recommended_column_num,
        product_slug,
        number_of_questions,
      } = this.state
      let temp_current_question = current_question
      const completed = (current_question + 1 === number_of_questions)
      if (skip_to_results) {
        temp_current_question = number_of_questions - 1
        this.setState({mounting:true})
      }
      if (current_question === 0) {
        this.setState({next_disabled: true})
        const user_fingerprint = await this.getFingerPrint()
        const result = await services.post_v2(APIURL.QUESTION_DATA,
          {
            'topic_slug': this.state.product_slug,
            'fingerprint': user_fingerprint,
            'referrer': this.state.referrer,
            'source': this.state.source,
            'campaign': this.state.campaign
          })
        if (result.response.data_uuid) {
          try {
            if (typeof window !== "undefined") {
              window.localStorage.setItem('data_uuid', result.response.data_uuid)
            }
          } catch {
            toast(
              <div>
                <div>
                  There was an error attempting to use cookies on your browser. Without cookies refreshing or closing the page will cause you to lose your progress.
                </div>
                <div className="pt-sm">
                  We recommend using Chrome browser or turning off &#34;Private Browsing Mode&#34; on Safari.
                </div>
              </div>
            , {autoClose: 15000})
          }
          const topic_data = await this.clearQuestionValues()
          this.setState({
            'user_fingerprint': user_fingerprint,
            'data_uuid': result.response.data_uuid,
            'topic_data': topic_data,
            'extras_added': true
          }, () => {
            this.props.router.push(this.state.path + this.state.product_slug+'/' + (current_question + 1) + '/')
          })
        }
      } else {
        let recommendations = CONSTANTS.RECOMMENDED_SELECTIONS[product_slug]
        let save_values = current_selection
        let save_secondary_values = null
        let save_input_values = null
        if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[current_question].question_type) > -1 ) {
          save_values = current_selections
          save_secondary_values = secondary_selections
          save_input_values = input_values
        }
        if (!current_selection) {
        } else if (['multiple_select'].indexOf(topic_data[current_question].question_type) > -1 && !save_values) {
          save_values = recommendations[topic_data[recommended_column_num]['value']][topic_data[current_question].column]
        } else if (!save_values) {
          save_values = recommendations[topic_data[recommended_column_num]['value']][topic_data[current_question].column][0]
        }
        const old_values = topic_data[current_question].value
        const updated_topic_data = topic_data
        updated_topic_data[current_question]['value'] = save_values
        updated_topic_data[current_question]['secondary_values'] = save_secondary_values
        updated_topic_data[current_question]['input_values'] = save_input_values
        const updated_options_data = options_data
        updated_options_data[updated_topic_data[current_question].column] = {
          question_type: updated_topic_data[current_question].question_type,
          value: updated_topic_data[current_question].value,
          locked_in: updated_topic_data[current_question].locked_in,
          secondary_values: updated_topic_data[current_question].secondary_values,
          secondary_column: updated_topic_data[current_question].secondary_column,
          related_reverse: updated_topic_data[current_question].related_reverse,
          input_values: updated_topic_data[current_question].input_values,
        }

        if (completed || skip_to_results) {
          this.setState({mounting:true})
          await services.put_v2(APIURL.QUESTION_DATA, {
            column: topic_data[current_question].column,
            value: save_values,
            secondary_column: topic_data[current_question].secondary_column,
            related_reverse: topic_data[current_question].related_reverse,
            secondary_values: save_secondary_values,
            input_values: save_input_values,
            data_uuid: data_uuid,
            question_number: current_question,
            unit: topic_data[current_question].unit,
            question_type: topic_data[current_question].question_type,
            shortened_title: topic_data[current_question].shortened_title,
            options_data: updated_options_data,
            completed: completed,
            engine: true
          })
          // console.log('GET PRODUCTS')
          const response = await services.post_v2(APIURL.ENGINE_PRODUCTS, {
               data_uuid: data_uuid,
               topic_name_plural: this.state.topic_name_plural
             }
          )
          if (response.statusCode === 200) {
            this.setState({
              matching_products: response.response,
            })
          } else {
            toast("An error occured. Please refresh page to try again.")
          }
        } else {
          services.put_v2(APIURL.QUESTION_DATA, {
            column: topic_data[current_question].column,
            value: save_values,
            secondary_column: topic_data[current_question].secondary_column,
            related_reverse: topic_data[current_question].related_reverse,
            secondary_values: save_secondary_values,
            input_values: save_input_values,
            data_uuid: data_uuid,
            question_number: current_question,
            unit: topic_data[current_question].unit,
            question_type: topic_data[current_question].question_type,
            shortened_title: topic_data[current_question].shortened_title,
            options_data: updated_options_data,
            completed: completed,
            engine: true
          })
        }
        this.setState({
          topic_data: updated_topic_data,
          current_selection: null,
          current_selections: null,
          secondary_selections: null,
          input_values: null,
          options_data: updated_options_data,
        }, async () => {
          if (completed || skip_to_results) {
            if (this.props.logged_in === 'true') {
              this.saveResults()
            }
            this.props.router.push(this.state.path + this.state.product_slug+'/' + (temp_current_question + 1) + '/?results=' + data_uuid).then(()=> {
              this.setState({
                mounting:false,
                results_page: true,
                results_url: process.env.NEXT_PUBLIC_DOMAIN + this.state.path + this.state.product_slug+'/' + (temp_current_question + 1) + '/?results=' + data_uuid
              })
            })
          } else {
            this.props.router.push(this.state.path + this.state.product_slug+'/' + (temp_current_question + 1) + '/')
          }
          if (typeof window !== "undefined") {
            window.scrollTo(0, 0)
          }
        })
      }
    } catch (e) {
      logError(e, "nextQuestion")
    }
  }

  buildQuestions = async (category) => {
    try {
      let topic_name = this.state.product_slug.replaceAll('-', ' ')
      const words = topic_name.split(" ");
      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      topic_name = words.join(" ");
      const questions_data = await services.get_v2(APIURL.RECOMMENDATION_ENGINE, { params: {product: topic_name, product_slug: this.state.product_slug }})
      const TOPIC_DATA = {}
      const options_data = {}


      // let recommended_column_num = 1
      for (const [index, [key, value]] of Object.entries(Object.entries(questions_data.questions))) {
        //fuckery to keep "info intro icon" from wrapping away from last word of value.question
        const questionIntoArray = value.question.split(' ')
        const lastWord = questionIntoArray[questionIntoArray.length-1]
        const firstWords = questionIntoArray.slice(0, questionIntoArray.length - 1).join(' ')
        //end fuckery: now I can join the last word, with the Icon
        const title = (
          <div className="q-title">
            <span dangerouslySetInnerHTML={{ __html: firstWords }}/>
            <span className='noWrap'>
              &nbsp;{lastWord}
              {value.sub_text && (
                <BsInfoCircleFill
                  id='InfoIntro'
                  className={'clickable'}
                  onClick={() => this.setState({description_toggle_bool : !this.state.description_toggle_bool})}
                />
              )}
            </span>
          </div>
        )
        // if (value.related_column === recommended_column) {
        //   recommended_column_num = parseInt(index) + 1
        // }
        TOPIC_DATA[parseInt(index) + 1] = {
          column: key,
          question_title: title,
          // top_image: value.top_image,
          options: value.options,
          // icon: <FiUsers className="fa-5x"/>,
          question_type: value.type,
          value: null,
          sub_text: value.description,
          option_image_height: value.option_image_height,
          option_image_width: value.option_image_width,
          secondary_values: null,
          input_values: null,
          shortened_title: value.shortened_title
        }
        options_data[value.id] = {
          question_type: value.type,
          popular: value.popular,
          value: null,
        }
      }
      // console.log(TOPIC_DATA)
      this.setState({
        options_data: options_data,
        // questions: QUESTIONS,
        topic_data: TOPIC_DATA,
        number_of_questions: Object.keys(TOPIC_DATA).length + 1,
        topic_name: topic_name,
        topic_name_plural: topic_name + 's',
      })
      // console.log(TOPIC_DATA)
      // return QUESTION_DATA
    } catch (e) {
      logError(e, "buildQuestions")
    }
  }

  startProductPicker = async (didMount=false) => {

    try {
      await this.buildQuestions()
      let topic_data = {}
      // console.log(this.props.router)
      let data_uuid = this.props.router.query.results
      let results_page = false
      let has_seen_results = false
      // let has_value = false
      let current_selection = null
      let current_selections = null
      let matching_products = null
      let results_url = null
      let alert_ids = []
      // if (this.props.logged_in) {
      //   const result = await services.get_v2(APIURL.PRICE_DROP_ALERTS, {params: {}})
      //   if (result.status === "success") {
      //     alert_ids = result.response.alert_ids
      //   }
      // }
      let referrer = ''
      let source = ''
      let campaign = ''
      if (document) {
        referrer = document.referrer
      }

      if (this.props && this.props.router && this.props.router.query) {
        if (this.props.router.query.src) {
          source = this.props.router.query.src
        }
        if (this.props.router.query.c) {
          campaign = this.props.router.query.c
        }
      }
      if (campaign === '' && source === '') {
        try {
          if (typeof window !== "undefined") {
            source = window.localStorage.getItem('source')
            campaign = window.localStorage.getItem('campaign')
          }
        } catch (e) {
          logError(e, "window.localStorage.getItem")
        }

      }

      if (data_uuid) {
        try {
          if (typeof window !== "undefined") {
            window.localStorage.setItem('data_uuid', data_uuid)
          }
        } catch {
          toast(
            <div>
              <div>
                There was an error attempting to use cookies on your browser. This may cause SimplyBuy to not work properly.
              </div>
              <div className="pt-sm">
                We recommend using Chrome browser or turning off &#34;Private Browsing Mode&#34; on Safari.
              </div>
            </div>
          , {autoClose: 15000})
        }
        results_page = true
        has_seen_results = true
      } else {
        try {
          if (typeof window !== "undefined") {
            data_uuid = window.localStorage.getItem('data_uuid')
          }
        } catch (e) {
          logError(e, "window.localStorage.getItem")
        }
      }
      let current_question = parseInt(this.props.router.query.question)
      if (data_uuid && current_question !== 0) {
        const question_data = await services.get_v2(APIURL.QUESTION_DATA, {
          params: {
            data_uuid: data_uuid,
            topic_slug: this.state.product_slug,
            engine: true
          }
        })
        if (question_data.statusCode === 404) {
          this.setState({
            mounting: false,
            referrer: referrer,
            source: source,
            campaign: campaign,
            has_seen_results: false,
            current_question: 0
          }, () => {
              this.props.router.push(this.state.path + this.state.product_slug+'/0/')
            }
          )
        } else {
          topic_data = await this.setQuestionValues(question_data)
          current_question = parseInt(this.props.router.query.question)
          let user_fingerprint = question_data.user_fingerprint
          if (topic_data[current_question] && ['multiple_select', 'related_table_multiple'].indexOf(topic_data[current_question].question_type) > -1) {
            current_selections = topic_data[current_question].value
          } else if (topic_data[current_question]) {
            current_selection = topic_data[current_question].value
          }
          if (data_uuid && current_question - 1 === Object.keys(topic_data).length) {
            const options_data = {}
            for (const i in topic_data) {
              options_data[topic_data[i].column] = {
                question_type: topic_data[i].question_type,
                value: topic_data[i].value,
                locked_in: topic_data[i].locked_in,
                secondary_values: topic_data[i].secondary_values,
                secondary_column: topic_data[i].secondary_column,
                related_reverse: topic_data[i].related_reverse,
                input_values: topic_data[i].input_values,
              }
            }
            // console.log('GET PRODUCTS')
            const response = await services.post_v2(APIURL.ENGINE_PRODUCTS, {
                 data_uuid: data_uuid,
                 topic_name_plural: this.state.topic_name_plural,
                 get_results: true
               }
            )
            if (response.statusCode === 200) {
              matching_products = response.response
            } else {
              toast("An error occured. Please refresh page to try again.")
            }
          }
          results_url = process.env.NEXT_PUBLIC_DOMAIN + this.state.path + this.state.product_slug+'/' + (current_question + 1) + '/?results=' + data_uuid
          const options_data = {}
          for (const i in this.state.topic_data) {
            options_data[this.state.topic_data[i].column] = {
              question_type: this.state.topic_data[i].question_type,
              value: this.state.topic_data[i].value,
              secondary_values: this.state.topic_data[i].secondary_values,
              locked_in: this.state.topic_data[i].locked_in,
              secondary_column: this.state.topic_data[i].secondary_column,
              related_reverse: this.state.topic_data[i].related_reverse,
              input_values: this.state.topic_data[i].input_values,
            }
          }
          this.setState({
            data_uuid: data_uuid,
            current_question: current_question,
            results_page: results_page,
            topic_data: topic_data,
            current_selection: current_selection,
            mounting: false,
            matching_products: matching_products,
            results_url: results_url,
            has_seen_results: has_seen_results,
            options_data: options_data,
            referrer: referrer,
            source: source,
            campaign: campaign,
            alert_ids: alert_ids,
            user_fingerprint: user_fingerprint
          }, () => {
            if (topic_data[current_question]) {
              if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[current_question].question_type) > -1) {
                this.makeSelectionMultiple(null, current_selections)
              } else {
                this.makeSelection(null, current_selection)
              }
            }
          })
        }
      } else {
        this.setState({
          mounting: false,
          referrer: referrer,
          source: source,
          campaign: campaign,
          has_seen_results: false,
          current_question: 0
        }, () => {
            this.props.router.push(this.state.path + this.state.product_slug+'/0/')
          }
        )
      }
    } catch (e) {
      logError(e, "startProductPicker")
    }
  }

  // componentDidMount = async () => {
  //   if ()
  //   this.setState({'didMount': true}).then(() => {
  //     if (this.startProductPicker())
  //   }
  //   console.log('componentDidMount')
  //   this.startProductPicker()
  // }

  componentDidMount() {
    if (this.fetchPromise) {
      return;
    }
    this.fetchPromise = this.startProductPicker()
  }

  componentDidUpdate = async () => {
    try {
      if (this.props && this.props.router && this.props.router.query) {
        if (this.props.router.query.slug !== this.state.product_slug && this.state.product_slug) {
          this.setState({mounting: true, product_slug: this.props.router.query.product_slug}, () =>{
            this.startProductPicker()
          })
        }
      }
      const { extras_added, current_question, results_page, topic_data, mounting, number_of_questions } = this.state
      if (!mounting) {
        const url_q_num = parseInt(this.props.router.query.question)
        // let disable_next = true
        if (url_q_num !== current_question) {
          // if (
          //   (topic_data[url_q_num] && (topic_data[url_q_num].value ||
          //   topic_data[url_q_num].value === false))) {
          // }
          const results_page = current_question === number_of_questions
          if (results_page) {
            this.setState({
              has_seen_results: true,
            })
          }
          this.setState({
            current_question: url_q_num,
            results_page: results_page,
          }, () => {
            if (topic_data[url_q_num]) {
              if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[url_q_num].question_type) > -1) {
                this.makeSelectionMultiple(null, topic_data[url_q_num].value)
              } else {
                this.makeSelection(null, topic_data[url_q_num].value)
              }
              this.add_recommendations(this.state.current_question)
            }
            if (this.state.results_page === true) {
              this.setState({has_seen_results: true})
            }
          })
        } else if (results_page !== (current_question === number_of_questions)) {
          if (current_question === number_of_questions) {
            if (!this.state.matching_products) {
              this.setState({mounting: true})
              this.startProductPicker()
            }
            else {
              if (this.state.has_seen_results === false && this.props.logged_in === 'false') {
                // setTimeout(() => {
                //   this.showSaveResultsModal(
                //     "Save Your Results",
                //     "Register now to have all your SimplyBuy results saved in one place!"
                //   ) }, 5000)
              }
              this.setState({
                results_page: current_question === number_of_questions,
                has_seen_results: current_question === number_of_questions
              })
            }
          } else {
            this.add_recommendations(this.state.current_question)
            this.setState({
              results_page: current_question === number_of_questions,
            })
          }
        } else {
          if(!this.state.results_page &&
             extras_added === false &&
             current_question !== 0) {
            let has_seen_results = true
            for (const question in this.state.topic_data) {
              if (!this.state.topic_data[question].value) {
                has_seen_results = false
              }
            }
            this.setState({extras_added: true, has_seen_results: has_seen_results}, () => {
              this.add_recommendations(this.state.current_question)
            })
          }
        }
      }
    } catch (e) {
      logError(e, "componentDidUpdate")
    }
  }

  setQuestionValues = async (question_data) => {
    try {
      question_data = JSON.parse(question_data.question_data)
      const topic_data = this.state.topic_data
      for (const question in topic_data) {
        if (question_data && question_data[topic_data[question].column] && question_data[topic_data[question].column].value) {
          topic_data[question].value = question_data[topic_data[question].column].value
        }
        if (question_data && question_data[topic_data[question].column] && question_data[topic_data[question].column].secondary_values) {
          topic_data[question].secondary_values = question_data[topic_data[question].column].secondary_values
        }
        if (question_data && question_data[topic_data[question].column] && question_data[topic_data[question].column].input_values) {
          topic_data[question].input_values = question_data[topic_data[question].column].input_values
        }
        if (question_data && question_data[topic_data[question].column]) {
          if (question_data[topic_data[question].column].locked_in === false) {
            topic_data[question].locked_in = false
          } else if (question_data[topic_data[question].column].locked_in === true) {
            topic_data[question].locked_in = true
          }
        }
      }
      return topic_data
    } catch (e) {
      return this.state.topic_data
    }
  }

  clearQuestionValues = async () => {
    const topic_data = this.state.topic_data
    for (const q in topic_data) {
      topic_data[q].value = null
      topic_data[q].secondary_values = null
      topic_data[q].input_values = null
    }
    return topic_data
  }

  progressClick = async (question) => {
    if (this.state.topic_data[question].value || this.state.topic_data[question].value === false) {
      this.setState({close_sidebar: true})
      this.props.router.push(this.state.path + this.state.product_slug+'/' + question + '/')
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0)
      }
    } else if (parseInt(question) === 1 || this.state.topic_data[question-1].value || this.state.topic_data[question-1].value === false) {
      this.setState({close_sidebar: true})
      this.props.router.push(this.state.path + this.state.product_slug+'/' + question + '/')
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0)
      }
    }
  }

  setCloseFalse = async () => {
    this.setState({close_sidebar: false})
  }

  hideSaveResultsModal = () => {
    this.setState({ show_save_results_modal: false })
  }

  makeCopy = async () => {
    const copy = await services.post_v2(APIURL.MAKE_COPY, {data_uuid: this.state.data_uuid})
    if (copy.response.data_uuid) {
      this.props.router.push(this.state.path + this.state.product_slug+'/' + this.state.current_question + '/?results=' + copy.response.data_uuid)
      toast('You are now on the copy!')
    }
  }

  // addLinkClick (product_id, source) {
  //   services.post_v2(APIURL.ADD_LINK_CLICK, {data_uuid: this.state.data_uuid, product_id: product_id, source: source})
  // }

  startFresh = async () => {
    this.setState({mounting: true}, () => {
      this.props.router.push(this.state.path + this.state.product_slug+'/0/')
      this.startProductPicker()
    })
  }

  saveResults = async () => {
    // const saved_results = await services.post_v2(APIURL.SAVE_RESULTS, {data_uuid: this.state.data_uuid})
    // if (saved_results.response.data_uuid !== this.state.data_uuid) {
    //   this.props.router.push(this.state.path + this.state.product_slug+'/' + this.state.current_question + '/?results=' + saved_results.response.data_uuid)
    //   toast('Results Saved!')
    // }
  }

  // toggleSwitch = () => {
  //   this.setState({ 'show_constants': !this.state.show_constants})
  // }

  goToSite (link, product_id, data_uuid, source, addLinkClick=true) {
    if (link.includes('avantlink')) {
      link = link + '&ctc=' + data_uuid
    }
    // if (addLinkClick) {
    //   this.addLinkClick(product_id, source)
    // }
    if (typeof window !== "undefined") {
      if (link.includes('amazon.com')) {
        window.open(link, '_blank', 'noopener')
      } else {
        window.open(link, '_blank', 'noopener,noreferrer')
      }
    }
  }

  sortByHighEndScore = (object1, object2) => {
    const obj1 = object1['top_pick_score_all']['score']
    const obj2 = object2['top_pick_score_all']['score']

    const obj1price = object1['price']
    const obj2price = object2['price']

    if (obj1 > obj2) {
      return -1
    }
    if (obj1 < obj2) {
      return 1
    }
    if (obj1 === obj2) {
      if (obj1price < obj2price) {
        return -1
      }
      if (obj1price > obj2price) {
        return 1
      }
    }
    return 0
  }


  highlightPopular = () => {
    const { current_selection, current_selections } = this.state
    if (!current_selection && (!Array.isArray(current_selections) || !current_selections.length)) {
      let selected_options = document.getElementsByClassName('recommended')
      for (let x in selected_options) {
        if(selected_options[x] && selected_options[x].classList) {
          selected_options[x].classList.add('will-add')
        }
      }
      selected_options = document.getElementsByClassName('recommended-with-tooltip')
      for (let x in selected_options) {
        if(selected_options[x] && selected_options[x].classList) {
          selected_options[x].classList.add('will-add')
        }
      }
    }
  }

  removeHighlight = () => {
    let selected_options = document.getElementsByClassName('recommended')
    for (let x in selected_options) {
      if(selected_options[x] && selected_options[x].classList) {
        selected_options[x].classList.remove('will-add')
      }
    }
    selected_options = document.getElementsByClassName('recommended-with-tooltip')
    for (let x in selected_options) {
      if (selected_options[x] && selected_options[x].classList) {
        selected_options[x].classList.remove('will-add')
      }
    }
  }

  toggleShowTipsOn = () => {
    this.setState({show_tips: true}, () => {
      this.end_product_tips.scrollIntoView({ behavior: "smooth" });
    })
  }

  toggleShowTipsOff = () => {
    this.setState({show_tips: false}, () => {
      // this.end_product_tips.scrollIntoView({ behavior: "smooth" });
    })
  }

  createFilters = (topic_data, related_button_container, group_topic_container) => {
    let progress = []
    let selections = []
    let constant_columns = []

    for (const question in topic_data) {
      let display_none = false
      if (topic_data[question].value) {
        const values = []
        var constant = false
        if (['multiple_select', 'related_table_multiple'].indexOf(topic_data[question].question_type) > -1) {
          if (topic_data[question].value.length === 1 && topic_data[question].value[0] !== '0' && topic_data[question].question_type !== 'related_table_multiple') {
            // UNCOMMENT CONSTANT_COLUMNS
            // constant = true
            // constant_columns.push(this.state.topic_data[question].column)
          } else if (topic_data[question].value.length === 1 && topic_data[question].value[0] === '0') {
            display_none = true
          }
          values.push(
            <div className="selection-values" key={question + '-unit'}>
              {add_unit(topic_data[question].value.join(', '), topic_data[question].unit, false)}
            </div>
          )
        } else {
          if (this.state.topic_data[question].value !== 0 && topic_data[question].value !== '0' && !['related_table', 'or_greater', 'or_less'].includes(topic_data[question].question_type)) {
            // UNCOMMENT CONSTANT_COLUMNS
            // constant = true
            // constant_columns.push(topic_data[question].column)
          }
          values.push(
            <div className="selection-values" key={question + '-unit'}>
              {add_unit(topic_data[question].value, topic_data[question].unit, false)}
            </div>
          )
        }

        if (topic_data[question].value === '0' || topic_data[question].value === ['0']) {
          display_none = true
        }

        selections.push(
          <div className="selection-row" key={'section-row-'+question}>

            <div key={question + '-link'} onClick={() => { this.progressClick(question) }} className={"pointer selection-row-flex " + classNames({'constant': constant === true})}>
              <div className="selection-numbers">{question}</div>
              <div className="filter">
              <div className="selection-labels">{topic_data[question].shortened_title}:</div>
              <div>{values}</div>
            </div>
          </div>
          </div>
        )
      }
      progress.push(
        <li
          onClick={() => { this.progressClick(question) }}
          className={ classNames({ 'active-current': this.state.current_question === parseInt(question), 'active': this.state.topic_data[question].value || this.state.topic_data[question].value === false })}
          key={question + '-progress'}
        >
        </li>)
    }
    return {progress: progress, selections: selections, constant_columns: constant_columns }
  }

  pushDefaultSpecs = (product) => {
    const productDetails = []
    productDetails.push(
      <div key={'row3'} className="flex-row alternate-color">
        <div className="label">Price</div>
        <div className="value">{product['price']}</div>
      </div>
    )
    return productDetails
  }

  getAlertsClick = async (product_id, exact_price, product_name, active_alert) => {
    if (this.props.logged_in === 'true') {
      const result = await services.post_v2(APIURL.PRICE_DROP_ALERTS,
        {
          product_id: product_id,
          exact_price: exact_price
        })
      if (result.status === "success") {
        if (result.response.muted === true) {
          toast(<div>
            <div className="toast-product-name">{product_name}</div>
            <div>Email Alerts Turned Off!</div>
          </div>)
        } else {
          toast(
            <div>
              <div className="toast-product-name">{product_name}</div>
              <div>Email Alerts Turned On!</div>
            </div>
          )
        }
        this.setState({'alert_ids': result.response.alert_ids})
      } else if (result.status === "IntegrityError") {
        toast('You already have email alerts for "' + product_name + '"')
      } else {
        toast('An error occured.')
      }
    } else {
      this.showSaveResultsModal(
        "Price Drops Alerts",
        "Sign up to get email alerts for price drops on this product. It's free and signing up unlocks other cool features like saving your results!"
      )
    }
  }

  createDisplayProduct = (product, product_details, product_type) => {
    const { alert_ids } = this.state
    const active_alert = alert_ids.indexOf(product['id']) >= 0 ? true : false
    let altLink
    let links = []
    let priceMatch = null
    let freeShipping = null
    let returns = null
    let returnPolicyUrl = null
    let returnPolicyDays = null
    let ordersOverShipFree = null
    let shippingPolicyUrl = null
    let base = ''
    let multiple = false

    let hidePrice = false
    if (product['link'] && product['link'].includes('amazon.com')) {
      hidePrice = false
    }
    // if (product['links']){
    //   // const chosenLink = this.chooseLink(product['links'])
    //   // base = chosenLink?.['base']
    //   const key1= Object.keys(product['links'])[0]
    //   if (product['link'].includes('amazon.com')) {
    //     hidePrice = true
    //   }
    // }
    if (product['link']) {
      if (product['link'].includes('amazon.com')) {
        links.push(
          <a
            key={'details-button-'+product['product_name']}
            className={"action-btn-gld continue-btn btn-m10 small see-details " + classNames({'blue': product_type === 'value'})}
            href={product['link']}
          >
            Amazon Link
          </a>
        )
      } else {
        links.push(
          <button
            className={"action-btn-gld continue-btn btn-m10 small see-details " + classNames({'blue': product_type === 'value'})}
            onClick= {() => {
              this.goToSite(product['link'], product['id'], this.state.data_uuid)
            }}
            key={'button-link'}>
              See Details
          </button>
        )
      }
    }
    if (product['retailer_link'] && product['on_amazon'] === false) {
      links.push(
        <a
          key={'details-button-'+product['product_name']}
          className={"action-btn-gld continue-btn btn-m10 small see-details " + classNames({'blue': product_type === 'value'})}
          href={product['retailer_link']}
        >
          Retailer Link
        </a>
      )
    }

    return (
      <div data-product-id={product.id}>
        <div className="product-top-container engine clickable"onClick={() => {this.goToSite(product['link'] ? product['link'] : altLink , product['id'], this.state.data_uuid)}}>
          <div className="product-img">
            {product['trimmed_product_image'] && (
              <Image
                layout="fill"
                objectFit="contain"
                placeholder={'blur'}
                blurDataURL={product['trimmed_product_image']}
                src={product['trimmed_product_image']}
                alt={'Product Image'} />
            )}
            {!product['trimmed_product_image'] && (
              <Image
                layout="fill"
                objectFit="contain"
                placeholder={'blur'}
                blurDataURL={"/no-image.png"}
                src={"/no-image.png"}
                alt={'No Image'} />
            )}
          </div>
          <div className="product-name">
            <div className="name">
              {product['name']}
            </div>
            <div className="by-brand">
              by <span className="brand">{product['brand']}</span>
            </div>
            {product.is_on_sale &&
              <div>
                <div className='original-price strike'>
                  {product['original_price']}
                </div>
                <div>
                <span className='price sale alt-margins'>
                  {product['price']} Sale
                </span>
                </div>
              </div>
            }
            {/*!product.is_on_sale &&
              <div className={"price " + classNames({'display-none': hidePrice})}>
                {product['price']}
              </div>
            */
          }
          </div>
        </div>
        {<div className={'actions engine' + classNames({'multiple-link-btns': multiple})}>
            {links}
        </div>}
        {/*<div className={'exclude-btn-container'}>
          <IoMdRemoveCircle data-tip data-for={'product-tt'} className={'clickable'} onClick={()=> {this.excludeProduct(product.id)}}/>
        </div>
        <ReactTooltip className='' id={'product-tt'} type='' effect='solid'>
          <div className="">Not interested in this product? Exclude it from these results.</div>
        </ReactTooltip>*/}
        <div className="product-details">
          <div className="slider-container">
            {product_details}
          </div>
        </div>
        <div className='feat-btn-container'>
          <div className='links-container'>
            {freeShipping &&
              <div className='policy-link' onClick={()=>{this.goToSite(base+shippingPolicyUrl, product.id, this.data_uuid, 'policy_link')}}>
                <GiCheckMark/> Free Shipping
              </div>
            }
            {priceMatch &&
              <div className='policy-link' onClick={()=>{this.goToSite(base+priceMatch, product.id, this.data_uuid, 'policy_link')}}>
                <GiCheckMark/> Price Match
              </div>
            }
          </div>
        </div>
      </div>

    )
  }

  chooseLink (links){
      let arr = []
      for (let link in links){
        arr.push(links[link])
      }
      arr.sort((a, b) =>{
        return b.sizes.length - a.sizes.length
      })
      return arr[0]
  }

  makeProductDetails = (product, is_value) => {
    let count = 0
    // console.log(topic_data)S
    // console.log(this.chooseLink(product['links']).link)
    const productDetails = []

    productDetails.push(
      <div key={'product'-product.id} className={'engine-description'}>{product.description}</div>
    )
    return productDetails
  }

  goBackToQuestionX = (x) => {
    this.props.router.push(this.state.path + this.state.product_slug+'/' + (x) + '/')
  }

  toggleEditFilters = () => {
    this.setState({edit_filters_toggle_bool: !this.state.edit_filters_toggle_bool})
    // this.setState({sidebar_toggle: false})
  }

  render () {
    const { topic_data, matching_products, related_button_container, group_topic_container } = this.state
    const {progress, selections, constant_columns} = this.createFilters(topic_data, related_button_container, group_topic_container)
    let display_highend_products = []
    // console.log(this.state)
    console.log(matching_products)
    let display_value_products = []
    if (this.state.results_page) {
      let high_end_1 = matching_products['high-end_1']
      let high_end_2 = matching_products['high-end_2']
      let value_1 = matching_products['value_1']
      let value_2 = matching_products['value_2']
      let productDetails = this.makeProductDetails(high_end_1, false)
      display_highend_products.push(this.createDisplayProduct(high_end_1, productDetails, 'highend'))
      productDetails = this.makeProductDetails(high_end_2, false)
      display_highend_products.push(this.createDisplayProduct(high_end_2, productDetails, 'highend'))
      productDetails = this.makeProductDetails(value_1, true)
      display_value_products.push(this.createDisplayProduct(value_1, productDetails, 'value'))
      productDetails = this.makeProductDetails(value_2, true)
      display_value_products.push(this.createDisplayProduct(value_2, productDetails, 'value'))
    }
    let sub_text = this.state.topic_data[this.state.current_question] ? this.state.topic_data[this.state.current_question].sub_text : ''
    const {
      // show_more,
      mounting,
      current_question,
      questions,
      results_page,
      close_sidebar,
      show_more_options,
      product_slug,
      show_more_highend,
      show_more_value,
      topic_status,
      sidebar_toggle,
    } = this.state
    let query_question = 0
    if (this.props && this.props.router && this.props.router.query && this.props.router.query.question) {
      query_question = this.props.router.query.question
    }
    // let maintenance = true
    // if (maintenance === true) {
    //   return (
    //     <div className='simplybuy-container'>
    //       <div className={'product-picker'}>
    //       SimplyBuy is undergoing temporary maintenance. We should be back by 12:30pm PST!
    //       </div>
    //     </div>
    //   )
    // }
    let navTabs = {}
    if (this.state.current_question > 0) {
      if (results_page) {
        navTabs['questions'] = {
          label: 'Results',
          logo: [<div key='btm-nav-results-img' className='img' />],
          // unfocused_logo: [<div class=src='/simplybuy-favicon-white.jpg' />],
          content: null
        }
      } else {
        navTabs['questions'] = {
          label: 'Questions',
          logo: [<div key='btm-nav-questions-img' className='img' />],
          content: null
        }
      }
      if (sub_text) {
        navTabs['question_description'] = {
          label: 'Description',
          logo: [<BsQuestionSquare key='bsquestionsquare'/>],
          content: [
            <div key='btm-nav-description' className="sub-text" dangerouslySetInnerHTML={{ __html: sub_text }} />
          ]
        }
      } else {
        if (!results_page) {
          navTabs['question_description'] = {
            label: 'Description',
            logo: [<BsQuestionSquare key='bsquestionsquare'/>],
            content: [
              <div key='btm-nav-description' className='p-md'>No description for this question.</div>
            ]
          }
        } else if (related_button_container.length > 0) {
          // related_button_container.push(group_topic_container)
          // console.log(related_button_container)
          let x = []
          x.push(
            <div key={'remove-inverse'} className='remove-inverse'>
              {related_button_container}
              {group_topic_container}
            </div>
          )
          // let x = related_button_container + group_topic_container
          let icon = null
          if (['snowboard', 'snowboard-binding'].includes(product_slug)) {
            icon = [<FaSnowboarding key='FaSnowboarding'/>]
          }
          navTabs['related_products'] = {
            label: 'Related Products',
            logo: icon,
            content: x
          }
        } else if (group_topic_container && group_topic_container.length > 0) {
          let icon = null
          let x = []
          x.push(
            <div key={'remove-inverse1'} className='remove-inverse'>
              {group_topic_container}
            </div>
          )
          icon = [<BsFillGrid3X3GapFill key='BsFillGrid3X3GapFill'/>]
          navTabs['related_products'] = {
            label: 'More Products',
            logo: icon,
            content: x
          }
        }
      }
      if (selections.length > 0) {
        navTabs['edit_filters'] = {
          label: 'Edit Filters',
          logo: [<IoMdOptions key='bssliders'/>],
          content: [
            <div key='btm-nav-filters'>
              <div className={"your-filters "}>
                <div>Filters</div>
              </div>
              {selections}
            </div>
          ]
        }
      } else {
        navTabs['edit_filters'] = {
          label: 'Edit Filters',
          logo: [<IoMdOptions key='bsquestionsquare'/>],
          content: [
            <div key='btm-nav-filter' className='p-md'>No filters yet.</div>
          ]
        }
      }
    }
    return (
      <div>
        {Object.keys(navTabs).length > 0 && (
          <BottomNav
            height={"25%"}
            spaceBetween={"results_page"}
            close={close_sidebar}
            setCloseFalse={this.setCloseFalse}
            tabText={'Edit Filters'}
            open_toggle={sidebar_toggle}
            tabs={navTabs}
            default_select={'questions'}
            description_toggle_bool ={this.state.description_toggle_bool}
            related_products_toggle_bool ={this.state.related_products_toggle_bool}
            edit_filters_toggle_bool = {this.state.edit_filters_toggle_bool}
            stateChanger = {this.setState}
          >
          </BottomNav>
        )}
        <div className='simplybuy-container no-padding-right'>
          {mounting && (
            <div className="spinner-container">
            {parseInt(query_question) === 0 && (
              <div>
                  <div className="ai-rec-engine">
                    <h1 className="">
                      AI Recommendation Engines
                    </h1>
                    <div key="beta" className="beta-tag1"><span>Beta</span></div>
                    <TypeAnimation
                        sequence={[
                          // Same substring at the start will only be typed once, initially
                          '',
                          2000,
                          'AI is generating the questions and guide...',
                          2000,
                          'Please hold tight. This will take a minute...',
                          2000,
                          'Please help us improve these recommendation engines. Give a thumbs up or thumbs down on the results page. Thank you!',
                          3000,
                          'This engine will be preserved to eliminate this wait time for future users.',
                          3000,
                        ]}
                        speed={50}
                        deletionSpeed={80}
                        style={{ fontSize: '1.2em' }}
                        repeat={'no'}
                    />
                  </div>
                </div>
            )}
            {parseInt(query_question) !== 0 && (
              <div>
                  <div className="ai-rec-engine">
                    <h1 className="">AI Recommendation Engines</h1>
                    <div key="beta" className="beta-tag1"><span>Beta</span></div>
                    <TypeAnimation
                        sequence={[
                          // Same substring at the start will only be typed once, initially
                          'Fetching your recommendations...',
                          2000,
                        ]}
                        speed={50}
                        deletionSpeed={80}
                        style={{ fontSize: '1.2em' }}
                        repeat={'no'}
                    />
                  </div>
                </div>
            )}

              <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
          )}
          {!mounting && (
            <div className={'product-picker ' + classNames({'display-none': mounting, 'padding-top-50': results_page })} >
              {!this.state.results_page && this.state.current_question !== 0 && (
                <div className="progess-container-calc">
                  <ul className="progressbar-calc">
                    {progress}
                  </ul>
                </div>
              )}
              <div className={"product-picker-content " + classNames({'no-max-width': this.state.results_page })}>
                <Question
                  topic_data={topic_data}
                  current_question={current_question}
                  show_more_options={show_more_options}
                  product_slug={product_slug}
                  nextQuestion={this.nextQuestion}
                  makeSelection={this.makeSelection}
                  makeSelectionMultiple={this.makeSelectionMultiple}
                  toggleShowMoreOptions={this.toggleShowMoreOptions}
                  setWidgetValues={this.setWidgetValues}
                  input_values={this.state.input_values}
                  goBackToQuestionX={this.goBackToQuestionX}
                  topic_status={topic_status}
                  logged_in={this.props.logged_in}
                  related_button_container={this.state.related_button_container}
                  topic_name={this.state.topic_name}
                  topic_name_plural={this.state.topic_name_plural}
                  slider_data={this.state.slider_data}
                  goToSite={this.goToSite}
                />
                {questions[current_question]}
                {results_page && (
                  <div>
                    <BestResult
                      mounting={mounting}
                      display_value_products={display_value_products.slice(0,3)}
                      display_highend_products={display_highend_products.slice(0,3)}
                      toggleShowMoreResults={this.toggleShowMoreResults}
                      toggleShowMoreHighendResults={this.toggleShowMoreHighendResults}
                      toggleShowMoreValueResults={this.toggleShowMoreValueResults}
                      show_more_value={show_more_value}
                      show_more_highend={show_more_highend}
                      toggleEditFilters={this.toggleEditFilters}
                      data_uuid={this.state.data_uuid}
                      related_button_container={this.state.related_button_container}
                      topic_slug={this.state.product_slug}
                      user_fingerprint={this.state.user_fingerprint}/>
                    {/*<CompareResults value_picks={value_picks} highend_picks={highend_picks} topic_data={topic_data} constant_columns={constant_columns}/>*/}
                  </div>
                )}
                {this.state.results_page && (
                  <div className={'mobile-only mt-lg'}>
                    {this.props.logged_in === 'true' && display_value_products.length > 0 && (
                      <div className=''>
                        <div className="btn-center-container result-options">
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                            () => { this.toggleEditFilters() }}>Edit Filters
                          </button>
                          <button className="action-btn-gld continue-btn btn-m10 small " onClick={
                            () => { this.props.router.push(PAGEURL.RECOMMENDATION_ENGINE) }}>New Engine
                          </button>
                        </div>

                      </div>
                    )}
                    {this.props.logged_in === 'false' && display_value_products.length > 0 && (
                      <div className=''>
                        <div className="btn-center-container result-options">
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                            () => { this.toggleEditFilters() }}>Edit Filters
                          </button>
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                            () => { this.props.router.push(PAGEURL.RECOMMENDATION_ENGINE) }}>New Engine
                          </button>
                          <button className="action-btn-gld continue-btn btn-m10 small" onClick={
                            () => {
                              this.showSaveResultsModal(
                                "Save Your Results",
                                "Create an account to have all your SimplyBuy results saved in one place!"
                              ) }}>Save Results
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {!this.state.results_page && this.state.current_question !== 0 && (
                  <div>
                    <div className="next-container">
                      {!this.state.has_seen_results && (
                        <button
                          disabled={this.state.next_disabled}
                          className="action-btn-gld continue-btn"
                          onClick={
                            () => { this.nextQuestion() }}
                          onMouseEnter={(e) => {this.highlightPopular()}}
                          onMouseLeave={(e) => {this.removeHighlight()}}
                            >
                          Next
                        </button>
                      )}
                      {this.state.has_seen_results && (
                        <button className="action-btn-gld continue-btn  results" onClick={
                          () => { this.nextQuestion(true) }}>See New Results
                        </button>
                      )}
                    </div>
                    <div className="sub-text hidden-sm-down" dangerouslySetInnerHTML={{ __html: sub_text }} />
                  </div>
                )}
              </div>
            </div>
          )}
          {current_question > 0 && (
            <div key={'section-container'} className="selection-container desktop">
              {this.state.results_page && (
                <div>
                  {this.props.logged_in === 'true' && (
                    <div className=''>
                      <div className="btn-center-container mt-6">
                        <button className="action-btn-gld continue-btn btn-m10 small xs" onClick={
                          () => { this.props.router.push(PAGEURL.RECOMMENDATION_ENGINE) }}>New Engine
                        </button>
                      </div>
                    </div>
                  )}
                  {this.props.logged_in === 'false' && (
                    <div className=''>
                      <div className="btn-center-container">
                        <button className="action-btn-gld continue-btn btn-m10 xs small" onClick={
                          () => { this.props.router.push(PAGEURL.RECOMMENDATION_ENGINE) }}>New Engine
                        </button>
                        <button className="action-btn-gld continue-btn btn-m10 xs small" onClick={
                          () => {
                            this.showSaveResultsModal(
                              "Save Your Results",
                              "Register now to have all your SimplyBuy results saved in one place!"
                            ) }}>Save Results
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className={"your-filters " + classNames({'no-margin': this.state.results_page})}>Filters</div>
              {selections}
            </div>
          )}
          <SaveResultsModal
            // handleSubmit={this.handleSubmit}
            show={this.state.show_save_results_modal}
            hide={() => { this.hideSaveResultsModal() }}
            results_url={this.state.results_url}
            data_uuid={this.state.data_uuid}
            current_question={this.state.current_question}
            product_slug={this.state.product_slug}
            handlelogin={this.props.handlelogin}
            router={this.props.router}
            title_text={this.state.title_text}
            description_text={this.state.description_text}
          />
        </div>
      </div>
    )
  }
}

RecommendationEngine.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  handlelogin: PropTypes.func,
  router: PropTypes.object,
}

// export default withRouter(ProductPicker)
export default RecommendationEngine
