import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { FormikField } from '../'
import { APIURL } from './../../constants'
import services from '../services'
import classNames from 'classnames'
import { add_unit } from '../utils'
import SelectField from './SelectField.js'
import { GrClose } from '@react-icons/all-files/gr/GrClose'
import { toast } from 'react-toastify';
import Nouislider from "@x1mrdonut1x/nouislider-react";
import 'react-toastify/dist/ReactToastify.css';
import "nouislider/distribute/nouislider.css"
import { logError } from '../Logger'
import Switch from './Switch.js'
import * as Yup from 'yup'
import {
  formatCM,
  formatShoeSizeMens,
  formatHeightMetric,
  formatMM,
  formatWeightMetric,
  formatWeight,
  formatHeight } from './widgets/formats'


toast.configure()
// import SelectField from '../../../../components/forms/SelectField'

// const slug_options = [
//   {value: '', label: 'All'},
//   {value: 'barbell', label: 'Barbells'},
//   {value: 'mountain-bike', label: 'Mountain Bikes'},
//   {value: 'ski', label: 'Skis'},
//   {value: 'snowboard', label: 'Snowboards'}
// ]

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_alerts: null,
      selections: null,
      show_preview: null,
      preview_array: [],
      mounting: true,
      slug_filter: '',
      slug_options: [],
      initial_load_complete: false,
      height: 0,
      startHeight: null,
      weight: 0,
      startWeight: null,
      shoeSize: 0,
      metricWeight: null,
      metricHeight: null,
      start_mWeight: null,
      start_mHeight: null,
      min: 0,
      max: 0,
      widthMin: 0,
      widthMax: 0,
      start: [],
      widthStart: [],
      lowerSize: 0,
      upperSize: 0,
      lowerWidth: 0,
      upperWidth: 0,
      ref: null,
      widthRef: null,
      shoeMin: 4,
      shoeMax: 14,
      shoeStart: null,
      use_metric: false,
      user_profile: null,
      user_name: null
    }
  }

  componentDidMount = async () => {
    if (this.props.logged_in) {
      const response = await services.get_v2(APIURL.USER_PROFILE, {params: {}})
      let user_info = response
      this.setState({
        user_profile: response,
        mounting: false,
        // startHeight: user_info.height || 67,
        // start_mHeight: user_info.metric_height || 171,
        // startWeight: user_info.weight || 180,
        // start_mWeight: user_info.metric_weight || 80,
        // shoeStart: user_info.shoe_size || 9,
        // use_metric: user_info.use_metric,
        // user_name: response.username
      })
    }
  }

  componentDidUpdate = async () => {
    if (this.props.logged_in && !this.state.initial_load_complete) {
      const response = await services.get_v2(APIURL.USER_PROFILE, {params: {}})
      let user_info = response
      this.setState({
        user_profile: user_info,
        mounting: false,
        startHeight: user_info.height || 67,
        start_mHeight: user_info.metric_height || 171,
        startWeight: user_info.weight || 180,
        start_mWeight: user_info.metric_weight || 80,
        shoeStart: user_info.shoe_size || 9,
        use_metric: user_info.use_metric,
        initial_load_complete: true
      })
    }
  }


 toggleSwitch = async (value, column) => {

 }

  handleChange = async (value, column) => {
    if(Array.isArray(value)) {
      value = value[0]
      value = parseFloat(value)
    }

    let response = await services.post_v2(APIURL.UPDATE_USER_INFO, {
      value: value,
      column: column
    })
    this.setState({[column]: value})
  }

  render () {

    const alerts = []
    const {
      user_profile,
      mounting,
      startHeight,
      startWeight,
      shoeStart,
      min,
      max,
      shoeMin,
      shoeMax,
      start_mHeight,
      start_mWeight

    } = this.state
    return (
      <div className='home'>
        <div className="home-container">
          <div className="manage-price-alerts">
            <h1 className={""}>Profile</h1>
            {mounting && (
              <div className="spinner-container">
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
            )}
            {!mounting && (
              <div className="profile-flex">
                <div className="user-info">
                  <div className='info-label'>
                    Email
                  </div>
                  <div className='info-value'>
                    {this.state.user_profile.username}
                  </div>
                  {this.state.user_profile.google_signup === false && (
                    <Formik
                      initialValues = {{
                        current_password: '',
                        new_password: '',
                        confirm_password: '',
                      }}
                      validationSchema={Yup.object({
                        current_password: Yup.string().required('Required'),
                        new_password: Yup.string().required('Required')
                          .required('Required'),
                        confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null],'Passwords do not match.').required('Required').typeError('Passwords do not match.')
                      })}
                      onSubmit={ async (values, { setSubmitting, setErrors, resetForm }) => {
                        // delete values['confirm_password']
                        const response = await services.put_v2(APIURL.CHANGE_PASSWORD, values)
                        if (response.token) {
                          localStorage.setItem('token', response.token);
                          toast('Your password has been changed!')
                          resetForm()
                          // this.props.handlelogin(null, response.token)
                          // this.props.hide()
                        } else {
                          setErrors(response)
                        }
                        setSubmitting(false)
                      }}
                    >
                      {props => (
                        <Form>
                          <div className="">
                            <FormikField name="current_password" label="Current Password" required={true} >
                              <Field className="form-field" name="current_password" placeholder="Current Password" type="password" />
                            </FormikField>
                            <FormikField name="new_password" label="New Password" required={true} >
                              <Field className="form-field" name="new_password" placeholder="New Password" type="password" />
                            </FormikField>
                            <FormikField name="confirm_password" label="Confirm Password" required={true} >
                              <Field className="form-field" name="confirm_password" placeholder="Confirm Password" type="password" />
                            </FormikField>
                            <div className="btn-center-container">
                              <button type="submit" className="action-btn-gld">Change Password</button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}

                </div>
                <div className="snowboard-widget">
                  <div className="calc-sliders">
                    <h2 className="pt-md pb-md">Use slider to change your default values for all sizing calculators.</h2>
                    <Switch
                      containerClasses={"switch-container mt-sm"}
                      label={'Use Metric Units'}
                      checked={this.state.use_metric}
                      switchChange={() => {
                        this.handleChange(!this.state.use_metric, 'use_metric')
                      }}/>
                    <div>
                      {!this.state.use_metric && (
                        <div>
                          <div className="slider-container">
                            <Nouislider
                              onUpdate={(value) => {this.handleChange(value, 'height')}}
                              step={1}
                              range={{ min: 58, max: 76 }}
                              start={[startHeight]}
                              tooltips={formatHeight(76)}
                              connect
                            />
                            <div className="slider-label">Height</div>
                          </div>
                          <div className="slider-container">
                            <Nouislider
                              onUpdate={(value) => {this.handleChange(value, 'weight')}}
                              step={5}
                              range={{ min: 90, max: 270 }}
                              start={[startWeight]}
                              tooltips={formatWeight(270)}
                            />
                            <div className="slider-label">Weight</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      {this.state.use_metric && (
                        <div>
                          <div className="slider-container">
                            <Nouislider
                              onUpdate={(value) => {this.handleChange(value, 'metric_height')}}
                              step={1}
                              range={{ min: 148, max: 194 }}
                              start={[start_mHeight]}
                              tooltips={formatHeightMetric(194)}
                              connect
                            />
                            <div className="slider-label">Height</div>
                          </div>
                          <div className="slider-container">
                            <Nouislider
                              onUpdate={(value) => {this.handleChange(value, 'metric_weight')}}
                              step={2}
                              range={{ min: 40, max: 120 }}
                              start={[start_mWeight]}
                              tooltips={formatWeightMetric(120)}
                            />
                            <div className="slider-label">Weight</div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="slider-container">
                      <Nouislider
                        onUpdate={(value) => {this.handleChange(value, 'shoe_size')}}
                        step={.5} range={{ min: shoeMin, max: shoeMax }}
                        start={[shoeStart]}
                        tooltips={formatShoeSizeMens(shoeMax)}
                        />
                      <div className="slider-label">{"Men's "}Shoe Size</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

UserProfile.propTypes = {
  logged_in: PropTypes.bool
}

// export default SavedResult
export default UserProfile
