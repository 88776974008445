import React from 'react'
import PropTypes from 'prop-types'
import { FaRegUserCircle } from '@react-icons/all-files/fa/FaRegUserCircle'
import Dropdown from "./Dropdown.js"
import LoginModal from "./LoginModal.js"
import { PAGEURL } from "./../../constants"
import { withRouter } from 'react-router-dom'
import ContactUs from './ContactUs'
import ReportBug from './ReportBug'
import classNames from 'classnames'


// @inject('store')
// @observer
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_contact_us: false,
      show_report_bug: false
    }
  }
  // hideLoginModal = () => {
  //   this.setState({ show_login_modal: false })
  // }
  // showLoginModal = () => {
  //   this.setState({ show_login_modal: true })
  // }

  hideContactUs = () => {
    this.setState({ show_contact_us: false })
  }

  hideReportBug = () => {
    this.setState({ show_report_bug: false })
  }

  contactUs = () => {
    this.setState({ show_contact_us: true, show_report_bug: false})
  }

  reportBug = () => {
    this.setState({ show_report_bug: true, show_contact_us: false})
  }

  about = () => {
    this.props.router.push(PAGEURL.ABOUT)
  }

  render () {
    const { logged_in } = this.props
    return (
      <div className={"simplybuy-footer " + classNames({'extra-padding': this.props.extraPadding})}>
        <div className="footer-container">
          <div onClick={() => {this.about()}}>About</div>
          <div onClick={this.contactUs}>Contact Us</div>
          <div onClick={this.reportBug}>Report Bug</div>
        </div>
        <ContactUs
          show={this.state.show_contact_us}
          hide={() => { this.hideContactUs() }}
        />
        <ReportBug
          show={this.state.show_report_bug}
          hide={() => { this.hideReportBug() }}
        />
      </div>
    )
  }
}

Footer.propTypes = {
  // logged_in: PropTypes.bool,
  // history: PropTypes.object,
  // match: PropTypes.object,
  // store: PropTypes.object
}

export default Footer
