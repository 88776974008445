import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { APIURL } from './../../constants'
import services from '../services'
import classNames from 'classnames'
import { add_unit } from '../utils'
import SelectField from './SelectField.js'
import { GrClose } from '@react-icons/all-files/gr/GrClose'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LazyImg from './LazyImg.js'
import { logError } from '../Logger'

toast.configure()
// import SelectField from '../../../../components/forms/SelectField'

const slug_options = [
  {value: '', label: 'All'},
  {value: 'barbell', label: 'Barbells'},
  {value: 'mountain-bike', label: 'Mountain Bikes'},
  {value: 'ski', label: 'Skis'},
  {value: 'snowboard', label: 'Snowboards'}
]

class SavedResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saved_results: null,
      selections: null,
      show_preview: null,
      preview_array: [],
      mounting: true,
      slug_filter: {value: '', label: 'All'},
      slug_options: [],
      initial_load_complete: false
    }
  }

  run = async () => {
    if (this.props.logged_in) {
      const response = await services.get_v2(APIURL.GET_SAVED_RESULTS, {params: {slug_filter: this.state.slug_filter.value}})
      this.setState({saved_results: response, mounting: false})
    }
  }

  componentDidMount = async () => {
    if (this.fetchPromise) {
      return;
    }
    this.fetchPromise = this.run()
  }

  componentDidUpdate = async () => {
    if (this.props.logged_in && !this.state.initial_load_complete) {
      const response = await services.get_v2(APIURL.GET_SAVED_RESULTS, {params: {slug_filter: this.state.slug_filter.value}})
      this.setState({saved_results: response, mounting: false, initial_load_complete: true})
    }
  }

  handleSelectFilterChange = async (e, filter_id) => {
    try {
      let value = ''
      if (e) {
        value = e.value
      }
      this.setState({mounting: true, slug_filter: e})
      const response = await services.get_v2(APIURL.GET_SAVED_RESULTS, {params: {slug_filter: value}})
      this.setState({saved_results: response, mounting: false})
    } catch (e) {
      logError(e, "handleSelectFilterChange")
    }
  }

  deleteSavedResult = async (saved_result_id) => {
    try {
      const response = await services.post_v2(APIURL.DELETE_SAVED_RESULT, {
        saved_result_id: saved_result_id
      })
      if (response.success) {
        toast(
          <div>
            <div>Saved Result Deleted!</div>
            <div className="pt-md">
              <a className="like-link" onClick={() => { this.undoDelete(saved_result_id) }}>Undo</a>
            </div>
          </div>
        )
        this.setState({saved_results: response.data})
      } else {
        toast.error('An error occured trying to delete this saved result')
      }
    } catch (e) {
      toast.error('An error occured trying to delete this saved result')
      logError(e, "Error Deleting Saved Result")
    }
  }

  undoDelete = async (saved_result_id) => {
    try {
      const response = await services.post_v2(APIURL.UNDO_DELETE_SAVED_RESULT, {
        saved_result_id: saved_result_id
      })
      if (response.success) {
        toast(<div>Delete Undone!</div>)
        this.setState({saved_results: response.data})
      } else {
        toast.error('An error occured trying to undo delete')
      }
    } catch (e) {
      toast.error('An error occured trying to undo delete')
      logError(e, "Error Deleting Saved Result")
    }
  }

  showPreview(question_data, i) {
    if (this.state.preview_array.includes(i)){
      let temp = this.state.preview_array
      const index = temp.indexOf(i);
      if (index > -1) {
        temp.splice(index, 1);
      }
      this.setState({preview_array: temp})
    } else {
      let temp = this.state.preview_array
      temp.push(i)
      this.setState({preview_array: temp})
    }

  }

  createSelections(question_data, i, uuid) {
    question_data = JSON.parse(question_data)
    const selections = []
    let count = 0
    for (const question in question_data) {
      let values
      count = count + 1
      if (Array.isArray(question_data[question].value)) {
        values = add_unit(question_data[question].value.join(', '), question_data[question].unit, false)
      } else {
        values = add_unit(question_data[question].value, question_data[question].unit, false)
      }
      selections.push(
        // <div className={"selection-row " + classNames({'constant': constant === true})}>
        <div key={question + '-selection'} className={"preview-row " + classNames({'alternate-color': count % 2 === 1})}>
          <div className={"label " + classNames({'alternate-color': count % 2 === 1})}>
            {question_data[question].shortened_title}
          </div>
          <div className={"value " + classNames({'alternate-color': count % 2 === 1})}>
            <strong>{values}</strong>
          </div>
        </div>
      )
    }
    return (selections)
  }

  render () {
    const results = []
    const { saved_results } = this.state
    for (const i in saved_results) {
      if (saved_results[i].question_data) {
        // const preview_text = this.state.preview_array.includes(i) : Hide Preview ? Preview
        results.push(
          <div key={i + '-result'} className="saved-result-container">
              <div className='top-row-flex'>
                <div className={'close-container'}>
                  <GrClose className="clickable" size={14} onClick={() => {
                    this.deleteSavedResult(saved_results[i].id)
                  }}/>
                </div>
              <div className="result-title">
                {saved_results[i].topic_name}
              </div>
            </div>
            <div className="clickable result-goto" onClick={() => {
                this.props.router.push(`/product-picker/${saved_results[i].topic_slug}/${saved_results[i].number_of_questions + 1}/?results=${saved_results[i].uuid}`)
              }}>
              <div className="product-img">
                {saved_results[i].top_pick_image && (
                  <LazyImg
                    height={'200px'}
                    effect={"blur"}
                    src={saved_results[i]['top_pick_image']} />
                )}
                {!saved_results[i].top_pick_image && (
                  <LazyImg
                    height={'200px'}
                    effect={"blur"}
                    src={"/no-image.png"} />
                )}
              </div>
              <div className="result-date">
                {saved_results[i].created_at}
              </div>
            </div>
            <div className="result-btns">
              <button className="action-btn-gld continue-btn btn-m10 xs" onClick={
                () => { this.showPreview(saved_results[i].question_data, i) }}>{this.state.preview_array.includes(i) ? 'Hide Preview' : 'Preview'}
              </button>
            </div>
            {this.state.preview_array.includes(i) &&(
              <div>
                {this.createSelections(saved_results[i].question_data, i, saved_results[i].uuid)}
              </div>
            )}
          </div>
        )
      }
    }
    return (
      <div className='simplybuy-container dark'>
          <div className="saved-results-container">
            <h1 className={"saved-results-header"}>Saved Results</h1>
            <div className={'filter-label'}>Product</div>
            <SelectField
              id={'slug-filter'}
              // defaultValue={slug_options[0]}
              className='filterSelect mb-xl'
              isClearable={true}
              onChange={(e) => { this.handleSelectFilterChange(e, 'slug-filter') } }
              options={slug_options}
              // placeholder={'All'}
              value={this.state.slug_filter}
              label={'filter'}>
            </SelectField>
            {results.length > 0 && (
              <div>
                {this.state.mounting && (
                  <div className="spinner-container not-full">
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </div>
                )}
                {!this.state.mounting && (
                <div className="saved-results-flex">
                  {results}
                </div>
                )}
              </div>
            )}
            {results.length === 0 && (
              <div>
              {this.state.mounting && (
                <div className="spinner-container not-full">
                  <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
              )}
              {!this.state.mounting && (
              <div className={'no-results'}>
                No saved results!
              </div>
              )}
              </div>
            )}
          </div>
      </div>
    )
  }
}

SavedResult.propTypes = {
  logged_in: PropTypes.bool
}

// export default SavedResult
export default SavedResult
