import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'
import { FaRegUserCircle } from '@react-icons/all-files/fa/FaRegUserCircle'
import Dropdown from "./Dropdown.js"
import LoginModal from "./LoginModal.js"
import { PAGEURL } from "./../../constants"
// import { withRouter } from 'react-router-dom'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Image from 'next/image'
import services from '../services'
import { APIURL } from './../../constants'
import AppContext from "./AppContext.js"
// import ReactGA from 'react-ga'


// @inject('store')
// @observer
export default function DefaultNav(props) {

  const [showLoginModal, setShowLoginModal] = useState(false);
  const { slug } = props.router.query
  const { router } = props
  const hideLoginModal = () => {
    setShowLoginModal(false)
  }
  const [showCommission, setShowCommission] = useState(false)
  const [showAdmin, setShowAdmin] = useState(false)
  let { logged_in, handlelogin, handlelogout } = useContext(AppContext);


  // const { logged_in, handlelogin, handlelogout } = props

  React.useEffect(() => {
    async function fetchData() {
      const response = await services.get_v2(APIURL.USER_PROFILE, {params: {}})
      if (response && response.commission_code) {
        setShowCommission(true)
      } else {
        setShowCommission(false)
      }
      if (response && response.is_admin) {
        setShowAdmin(true)
      } else {
        setShowAdmin(false)
      }
    }

    if (logged_in === "true") {
      fetchData()
    } else {
      setShowCommission("false")
    }
  }, [logged_in])

  const savedResults = () => {
    router.push(PAGEURL.SAVED_RESULTS)
  }

  const home = () => {
    router.push(PAGEURL.HOME)
  }

  const userAlerts = () => {
    router.push(PAGEURL.MANAGE_ALERTS)
  }

  const userProfile = () => {
    router.push(PAGEURL.PROFILE)
  }

  const commissionReports = () => {
    router.push(PAGEURL.COMMISSION_REPORTS)
  }

  const commissionAdmin = () => {
    router.push(PAGEURL.COMMISSION_ADMIN)
  }

  let userMenu = [
    {buttonTitle: 'Profile', onClick: userProfile},
    {buttonTitle: 'Saved Results', onClick: savedResults},
    {buttonTitle: 'Manage Price Alerts', onClick: userAlerts},
    {buttonTitle: 'Logout', onClick: handlelogout}
  ]

  if (showCommission) {
    userMenu = [
      {buttonTitle: 'Profile', onClick: userProfile},
      {buttonTitle: 'Saved Results', onClick: savedResults},
      {buttonTitle: 'Manage Price Alerts', onClick: userAlerts},
      {buttonTitle: 'Commission Reports', onClick: commissionReports},
      {buttonTitle: 'Logout', onClick: handlelogout}
    ]
  } else if (showAdmin) {
    userMenu = [
      {buttonTitle: 'Profile', onClick: userProfile},
      {buttonTitle: 'Saved Results', onClick: savedResults},
      {buttonTitle: 'Manage Price Alerts', onClick: userAlerts},
      {buttonTitle: 'Commission Admin', onClick: commissionAdmin},
      {buttonTitle: 'Logout', onClick: handlelogout}
    ]
  }


  // const { logged_in } = props
  return (
    <nav key={'nav'} className="nav-bar">
      <div className="nav-bar-container ml-md">
        <Link className="logo-img" href={PAGEURL.HOME}>
          <Image
            height={30}
            width={150}
            src='/simplybuy-logo.webp'
            alt="simplybuy.io"
            className="logo-img"
            priority={true}/>
        </Link>
        {logged_in === "true" && (
          <div>
            <div className="flex-items right-nav-links">
              <Link className="nav-link pointer" href={PAGEURL.BLOG_}>Blog</Link>
              <Dropdown menuItems={userMenu} >
                <FaRegUserCircle className="pointer"/>
              </Dropdown>
            </div>
          </div>
          )}
        {logged_in === "false" && (
          <div className="flex-items right-nav-links">
            <Link className="nav-link pointer" href={PAGEURL.BLOG_}>Blog</Link>
            <Link className="nav-link pointer" href={PAGEURL.ABOUT}>About</Link>
            <div className="nav-link pointer" onClick={() => {setShowLoginModal(true)}}>
              Login
            </div>
          </div>
        )}
      </div>
      <LoginModal
        handlelogin={handlelogin}
        show={showLoginModal}
        hide={() => { hideLoginModal() }}
      />
    </nav>
  )
}

// DefaultNav.propTypes = {
//   logged_in: PropTypes.bool,
//   handleLogout: PropTypes.func,
//   handleLogin: PropTypes.func,
//   history: PropTypes.object,
//   // match: PropTypes.object,
//   // store: PropTypes.object
// }

// export default DefaultNav
