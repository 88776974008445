import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Nouislider from "@x1mrdonut1x/nouislider-react";
import "nouislider/distribute/nouislider.css";
import 'react-toastify/dist/ReactToastify.css'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import Switch from './Switch.js'
import services from '../services'
import { APIURL } from './../../constants'
import { formatShoeSizeMens } from './widgets/formats'

const START_SIZE = 9
const MIN_SIZE = 4
const MAX_SIZE = 15

toast.configure()

class BootsSliders extends Component {
  constructor (props) {
    console.log('constructor')
    super(props)
    const td = this.props.topic_data
    let start_value = START_SIZE
    let wide = 'False'
    if (td && td[3] && td[3].value) {
      console.log(td[3])
      start_value = td[3].value
      wide = td && td[3].secondary_values && td[3].secondary_values === 'True' ? 'True' : 'False'
    }
    console.log(wide)
    this.state = {
      show_sliders: true,
      min: MIN_SIZE,
      max: MAX_SIZE,
      start: start_value,
      bootSize: start_value,
      ref: null,
      rendering: false,
      wide: wide
    }
  }

  // checkIfValue (td, key) {
  //   return (td && td[2].value)
  // }

  // componentDidMount = () => {
  //
  // }
  //
  // componentDidUpdate = () => {
  //
  // }

  toggleSwitch = () => {
    if (this.state.wide === 'False') {
      this.setState({wide: 'True'}, () => this.createRange(this.state.bootSize))
    } else {
      this.setState({wide: 'False'}, () => this.createRange(this.state.bootSize))
    }
  }

  createRange = (value) => {
    console.log(value, this.state.wide)
    if (value[0].includes('.5')) {
      value[0] = String(Math.round(value[0] * 10) / 10)
    }
    else {
      value[0] = String(parseInt(value[0]))
    }
    if (this.state.show_sliders) {
      this.props.setWidgetValues(value, this.state.wide, {}, false, null)
    }
  }

  checkSizes1 = (value) => {
      // if (value.includes('.5')) {
      //   value = String(Math.round(value * 10) / 10)
      // } else {
      //   value[0] = String(parseInt(value[0]))
      // }
      // value = Math.round(value * 10) / 10
      this.setState({bootSize: value})
      this.createRange(value)
  }


  render () {
    const {
      min,
      max,
      start,
      rendering,
    } = this.state
    return (
      <div className='snowboard-widget'>
        <div class="calc-sliders tv">
        {rendering && (
          <div className="spinner-container">
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        )}
        {!rendering && (
            <div className={'slider-fade ' + classNames({'display-none': !this.state.show_sliders})}>
              <div className="slider-container bottom-sliders">
                <Nouislider
                  onUpdate={(e) => {this.checkSizes1(e)}}
                  step={.5}
                  range={{ min: min, max: max }}
                  start={start}
                  tooltips={formatShoeSizeMens(max, true)}
                />
                <div className="slider-label">{this.props.topic_data[1].value ==='womens' ? "Women's ": "Men's "}Shoe Size</div>
                <Switch containerClasses={"switch-container mt-sm"} notSmall={true} label={'Wide sized boots?'} checked={(this.state.wide === 'True')} switchChange={this.toggleSwitch}/>
              </div>
            </div>
        )}
        </div>
      </div>
    )
  }
}

BootsSliders.propTypes = {
  topic_data: PropTypes.object,
  goBackToQuestionX: PropTypes.func,
  logged_in: PropTypes.bool

}

export default BootsSliders
