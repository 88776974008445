import React, { Component } from "react";
import PropTypes from 'prop-types'
import Chart from 'chart.js/auto';
// import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js';
import { Radar } from "react-chartjs-2";

// ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

class RadarComponent extends Component {
  render() {
    const { labelArray, dataArray } = this.props.data
    const { is_value, slug } = this.props
    const color = is_value ? "rgba(6, 190, 240, .6)" : "rgba(240, 135, 6, .6)"
    const color2 = is_value ? "rgba(6, 190, 240, 1)" : "rgba(240, 135, 6, 1)"
    let min_value = 1.5
    if (slug == 'monitor') {
      min_value = 2.5
    }
    const RadarData = {
      labels: labelArray,
      datasets: [
        {
          label: "",
          backgroundColor: color,
          borderColor: color2,
          borderWidth: 4,
          pointBackgroundColor: color2,
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(1, 1, 1, 1)",
          data: dataArray
        }
      ]
    }

    const RadarOptions = {
      ticks: {
        display: false,
      },
      scales: { // <-- Note change in options from scale to scales
        r: {
          gridLines: {
            color: 'red',
          },
          ticks: {
            backdropColor: 'black',
            userCallback: function (value, index, values) {
              Math.round(Math.pow(2,value)); //SO this will make the scale a logrithmic
            },
            display: false // Hides the labels in the middel (numbers)
          },
          pointLabels: {
            font: {
              size: 12,
            }
          },
          grid: {
            lineWidth: 1.5,
             color: "rgba(180, 180, 180, .5)",
          },
        }
      },
      aspectRatio: 1.33,
      scale: {
        min: min_value,
        max: 5,
        display: false,
        ticks: {
          // stepSize: 1,
          userCallback: function (value, index, values) {
            Math.round(Math.pow(2,value)); //SO this will make the scale a logrithmic
            },
        },
      },
      plugins: {
        tooltip: {
          displayColors: false
        },
        legend: {
          display: false
        }
      },
    }
    return (
      <div>
      <Radar ref={this.chartRef} data={RadarData} options={RadarOptions} />
      </div>
    );
  }
}

RadarComponent.propTypes = {
  data: PropTypes.object,
  is_value: PropTypes.bool,
  slug: PropTypes.string
}

export default RadarComponent
