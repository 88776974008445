import '../styles/App.scss'
import React, { useState, useEffect, createContext, useContext } from 'react';
import { useRouter } from 'next/router'
import usePageTracking from '../components/usePageTracking'
import { logInit } from '../components/Logger'
import AppContext from '../components/common/AppContext'
import { Head } from 'next/document'
import { DefaultLayout } from '../layouts'

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  // const getLayout = Component.getLayout || ((page) => page)
  usePageTracking(router)
  if (router.pathname === '/iframe/[slug]/[question]') {
    return(
      <div className="iframe">
          <Component {...pageProps} />
          </div>
    )
  }
  return(
      <DefaultLayout>
        <Component {...pageProps} />
      </DefaultLayout>
  )
  // usePageTracking(router);
  // return (
  //     <Component {...pageProps} ></Component>
  // )
}

export default MyApp
